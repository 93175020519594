<div class="system">
  <app-sidebar class="sidebar"></app-sidebar>

  <div class="wrapper">
    <app-header></app-header>
      <div class="layout">
        <router-outlet></router-outlet>
      </div>
    </div>
</div>
