<apx-chart
  [series]="barSeries!"
  [chart]="apexChart"
  [xaxis]="apexXaxis"
  [dataLabels]="apexDataLabels"
  [grid]="apexGrid"
  [title]="{text: title}"
  [plotOptions]="apexPlotOptions"
  [labels]="labels"
  #chart
></apx-chart>
