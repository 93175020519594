import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { GeneralFilter } from 'src/app/_core/_models/filter-models/general-filter';
import { MonitoringService } from 'src/app/_core/_services/monitoring.service';
import { environment } from 'src/environments/environment';
import { MonitoringProjectModel } from '../_models/monitoring-project.model';

@Injectable({
    providedIn: 'root'
})
export class MonitoringProjectService extends MonitoringService {
    constructor() {
        super();
    }

    public get(filter: GeneralFilter): Observable<MonitoringProjectModel[]> {
        return super.callGet('/analytics/grouped-projects-publications', filter).pipe(
            map(res => {
                this.changeData(res, true);
                return res;
            })
        );
    }
}
