import { Component, OnInit } from '@angular/core';
import { MobileService } from '../../_services/mobile.service';

@Component({
    selector: 'app-right-sidebar',
    templateUrl: './right-sidebar.component.html',
    styleUrls: ['./right-sidebar.component.scss']
})
export class RightSidebarComponent implements OnInit {
    public isFilterOpen: boolean = false;

    constructor(private _mobileService: MobileService) {}

    ngOnInit(): void {
        this._mobileService.isFilterOpenObservable$.subscribe(value => (this.isFilterOpen = value));
    }

    public closeFilter(): void {
        this._mobileService.isFilterOpen = false;
    }
}
