import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { GeneralFilter } from 'src/app/_core/_models/filter-models/general-filter';
import { ReportDto } from 'src/app/_core/_models/report.model';
import { MonitoringService } from 'src/app/_core/_services/monitoring.service';
import { environment } from 'src/environments/environment';
import { ToneHoursModel } from '../_models/tone-hours.model';

@Injectable({
    providedIn: 'root'
})
export class ToneHoursService extends MonitoringService {
    constructor() {
        super();
    }

    public get(filter: GeneralFilter): Observable<ReportDto[]> {
        return super.callGet('/analytics/grouped-tone-todays-hours', filter).pipe(
            map(res => {
                super.changeData(res);
                return res;
            })
        );
    }
}
