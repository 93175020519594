import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-social-network',
  templateUrl: './card-social-network.component.html',
  styleUrls: ['./card-social-network.component.scss']
})
export class CardSocialNetworkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
