import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, map, BehaviorSubject, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BookmarkModel } from '../_models/bookmark.model';
import { EntryModel } from '../_models/entry.model';
import { GeneralFilter } from '../_models/filter-models/general-filter';

@Injectable({
    providedIn: 'root'
})
export class FavouritesService {
    private readonly _data;
    public _data$;
    private readonly _filteredData;
    public _filteredData$;
    private isLoading: boolean = false;

    constructor(private _http: HttpClient) {
        this._data = new BehaviorSubject<BookmarkModel[]>([]);
        this._data$ = this._data.asObservable();

        this._filteredData = new Subject<BookmarkModel[]>();
        this._filteredData$ = this._data.asObservable();
    }

    public getFavourites(): Observable<any> {
        return this._http.get(`${environment.apiUrl}/bookmarks`);
    }

    public getAll(): Observable<Array<BookmarkModel>> {
        if (this.isLoading) return of();

        if (this._data.getValue().length !== 0) {
            this._data.next(this._data.getValue());
            return of();
        }
        this.isLoading = true;
        return this._http.get<Array<BookmarkModel>>(`${environment.apiUrl}/bookmarks`).pipe(
            map(res => {
                this.changeData(res);
                this.isLoading = false;
                return res;
            })
        );
    }

    private changeData(data: Array<BookmarkModel>): void {
        this._data.next(data);
    }

    public getByFilter(filter: GeneralFilter): Observable<Array<BookmarkModel>> {
        let queryParams = new HttpParams();
        Object.keys(filter).map(k => {
            let value = (filter as any)[k];
            queryParams = value ? queryParams.append(k, value) : queryParams;
        });

        return this._http
            .get<Array<BookmarkModel>>(`${environment.apiUrl}/entries`, {
                params: queryParams
            })
            .pipe(
                map(res => {
                    this.changeData(res);
                    return res;
                })
            );
    }

    public create(b: BookmarkModel): Observable<BookmarkModel> {
        return this._http.post<BookmarkModel>(`${environment.apiUrl}/bookmarks`, b);
    }

    public getById(id: number): Observable<BookmarkModel> {
        return this._http.get<BookmarkModel>(`${environment.apiUrl}/bookmarks/${id}`);
    }

    public update(data: BookmarkModel): Observable<void> {
        return this._http.put<void>(`${environment.apiUrl}/bookmarks`, {
            ...data
        });
    }

    public delete(id: number): Observable<void> {
        return this._http.delete<void>(`${environment.apiUrl}/bookmarks/${id}`);
    }

    public setBookmarks(entryId: number, bm: BookmarkModel): Observable<EntryModel> {
        let queryParams = new HttpParams();

        queryParams = queryParams.append('bookmarkIds', bm.id);
        return this._http.put<EntryModel>(`${environment.apiUrl}/entries/bookmarks/add/${entryId}`, null, { params: queryParams });
    }

    public deleteBookmarks(entryId: number, bm: BookmarkModel): Observable<EntryModel> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('bookmarkIds', bm.id);
        return this._http.put<EntryModel>(`${environment.apiUrl}/entries/bookmarks/delete/${entryId}`, null, { params: queryParams });
    }

    public getByEntryId(entryId: number): Observable<Array<BookmarkModel>> {
        return this._http.get<BookmarkModel[]>(`${environment.apiUrl}/entries/bookmarks/find/${entryId}`);
    }
}
