import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { ProjectModel } from 'src/app/_core/_models/project-model';
import { INotifier } from 'src/app/_core/_models/_notifier/notifier.model';
import { NotificationsService } from 'src/app/_core/_services/notifications.service';
import { ProjectService } from 'src/app/_core/_services/project.service';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
    public isLoading: boolean;
    public isLoadingContent: boolean;
    public form: FormGroup;
    public notifications: Array<INotifier>;
    public projects: Array<ProjectModel>;

    public projectsNameList: Array<string>;

    public choosenProject: ProjectModel;

    constructor(
        private _notificationsService: NotificationsService,
        private _projectService: ProjectService,
        private _toastrService: ToastrService
    ) {
        this._createForm();
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.isLoadingContent = true;
        combineLatest({
            notifications: this._notificationsService.getNotifications(),
            projects: this._projectService.getAll()
        }).subscribe({
            next: response => {
                this.notifications = response.notifications;
                this.projects = response.projects;
                this.isLoading = false;
                this.isLoadingContent = false;
            }
        });
    }

    public handleSubmitForm(): void {
        this.isLoading = true;
        this._notificationsService
            .saveNotifier({
                email: this.form.controls['email'].value,
                projectId: this.choosenProject?.id
            })
            .subscribe({
                next: response => {
                    this.form.reset();
                    this._toastrService.success('Электронная почта добавлена для уведомлений');
                    this.isLoading = false;
                    this.ngOnInit();
                }
            });
    }

    public handleDeleteNotification(id: number): void {
        this.isLoading = true;
        this._notificationsService.deleteNotifier(id).subscribe(response => {
            this._toastrService.error('Электронная почта удалена из уведомлений');
            this.isLoading = false;
            this.ngOnInit();
        });
    }

    public onItemClick(project: ProjectModel): void {
        this.choosenProject = project;
    }

    public _createForm(): void {
        this.form = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email])
        });
    }
}
