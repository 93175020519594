<div class="card-main">
    <a class="link" [href]="entry.url" target="_blank">
        <div class="title">{{ entry.title ? entry.title : entry.authorName }}</div>
    </a>
    <div class="date-block">
        <div class="date-time">{{ entry.date | date: 'dd/MM/yy hh:mm' }}</div>
        <a *ngIf="source" [href]="entry.url" target="_blank" class="source">{{ source | titlecase }}</a>
    </div>
    <!-- <div class="text" [innerHTML]="entry.text"></div> -->
    <div class="text" [innerHtml]="text | safeHtml"></div>

    <div class="status" *ngIf="entry">
        <div
            title="Изменить тон"
            class="status__item status__item_success"
            [ngClass]="{ selected: entry.tone === 'POSITIVE' }"
            [title]="entry.tone !== 'POSITIVE' ? 'Изменить тон на позитивный' : ''"
            (click)="entry.tone !== 'POSITIVE' && changeTone('POSITIVE')"
        ></div>
        <div
            class="status__item status__item_warning"
            [ngClass]="{ selected: entry.tone === 'NEUTRAL' }"
            [title]="entry.tone !== 'NEUTRAL' ? 'Изменить тон на нейтральный' : ''"
            (click)="entry.tone !== 'NEUTRAL' && changeTone('NEUTRAL')"
        ></div>
        <div
            class="status__item status__item_error"
            [ngClass]="{ selected: entry.tone === 'NEGATIVE' }"
            [title]="entry.tone !== 'NEGATIVE' ? 'Изменить тон на негативный' : ''"
            (click)="entry.tone !== 'NEGATIVE' && changeTone('NEGATIVE')"
        ></div>
    </div>
    <div class="btn-bookmarks">
        <app-odropdown
            [multi]="true"
            [selectedList]="selectedBookmarks || []"
            [list]="bookmarks || []"
            [fieldName]="'name'"
            (clickEvent)="bookmarkCheckListener($event)"
            (openEvent)="onOpenBookmark()"
        >
            <ng-container *ngIf="entry.bookmarks && entry.bookmarks.length">
                <div class="d-flex">
                    <div class="bookmark" [title]="item" *ngFor="let item of favouriteLabels">
                        {{ item }}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!entry.bookmarks || (entry.bookmarks && !entry.bookmarks.length)">
                <div class="dropdown">
                    <div class="label">Выбрать избранное</div>
                    <img class="logo" src="assets/icons/arrow-down.svg" alt />
                </div>
            </ng-container>
        </app-odropdown>
    </div>
</div>
