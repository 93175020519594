import { Component, OnInit, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectModel } from 'src/app/_core/_models/project-model';
import { ProjectService } from 'src/app/_core/_services/project.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-project-change',
    templateUrl: './project-change.component.html',
    styleUrls: ['./project-change.component.scss']
})
export class ProjectChangeComponent implements OnInit {
    public projects: Array<ProjectModel> = [];
    public formArray: FormArray;
    public newProjectFormGroup: FormGroup;

    public isAddLoading: boolean = false;
    public isEditLoading: boolean = false;
    public isDeleteLoading: boolean = false;
    public isDataLoading: boolean = false;

    constructor(
        private _projectService: ProjectService,
        public dialogRef: MatDialogRef<ProjectChangeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _fb: FormBuilder,
        private toastr: ToastrService
    ) {}

    onCancel(): void {
        this.dialogRef.close();
    }

    ngOnInit(): void {
        this.isDataLoading = true;
        this.newProjectFormGroup = this.genFormGroup();
        this._projectService.getAll().subscribe({
            next: res => {
                this.projects = res;
                this.formArray = this.genFormArray(this.projects);
                this.isDataLoading = false;
            },
            error: () => (this.isDataLoading = false)
        });
    }

    public genFormArray(projects: Array<ProjectModel>): FormArray {
        let fa: FormArray = this._fb.array([]);
        projects.forEach(proj => {
            let fg: FormGroup = this.genFormGroup();
            fg.reset(proj);
            fa.push(fg);
        });
        fa.disable();
        return fa;
    }

    public change(fg: FormGroup): void {
        this.isEditLoading = true;
        let project = this.fgToModel(fg);
        fg.disable();
        this._projectService.update(project).subscribe({
            next: () => {
                this.isDeleteLoading = false;
                this.toastr.success('Проект успешно изменён ');
            },
            error: () => {
                this.isDeleteLoading = false;
                this.toastr.error('Ошибка, проект не изменён');
            }
        });
    }

    public delete(fg: FormGroup, i: number): void {
        if (!confirm('Вы уверены, что хотите удалить проект? ')) {
            return;
        }
        this.isDeleteLoading = true;
        let project = this.fgToModel(fg);
        this._projectService.delete(project.id).subscribe({
            next: response => {
                this.formArray.removeAt(i);
                this.isDeleteLoading = false;
                this.toastr.success('Проект успешно удалён ');
            },
            error: () => {
                this.isDeleteLoading = false;
                this.toastr.error('Ошибка, проект не удалён');
            }
        });
    }

    public add(): void {
        this.isAddLoading = true;
        let project = this.fgToModel(this.newProjectFormGroup);
        this._projectService.create({ name: project.name }).subscribe({
            next: response => {
                let fg = this.genFormGroup(response.id, response.name);
                fg.disable();
                this.formArray.insert(0, fg);
                this.newProjectFormGroup = this.genFormGroup();
                this.isAddLoading = false;
                this.toastr.success('Проект успешно добавлен ');
            },
            error: () => {
                this.isAddLoading = false;
                this.toastr.error('Ошибка, проект не добавлен');
            }
        });
    }

    public genFormGroup(id?: number, name?: string): FormGroup {
        return this._fb.group({
            id: id ? id : null,
            name: name ? name : ''
        });
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    public fgToModel(fg: FormGroup): ProjectModel {
        let obj;
        if (fg) {
            obj = fg.getRawValue();
        } else {
            obj = this.newProjectFormGroup.getRawValue();
        }

        return {
            id: obj.id,
            name: obj.name
        };
    }
}
