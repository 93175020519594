import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BookmarkChangeComponent } from 'src/app/_core/_components/bookmark-change/bookmark-change.component';
import { TagChangeComponent } from 'src/app/_core/_components/tag-change/tag-change.component';
import { BookmarkModel } from 'src/app/_core/_models/bookmark.model';
import { EntryModel } from 'src/app/_core/_models/entry.model';
import { ReportModel } from 'src/app/_core/_models/report.model';
import { EntryService } from 'src/app/_core/_services/entry.service';
import { FavouritesService } from 'src/app/_core/_services/favourites.service';

@Component({
    selector: 'app-bookmarks',
    templateUrl: './bookmarks.component.html',
    styleUrls: ['./bookmarks.component.scss']
})
export class BookmarksComponent implements OnInit {
    public filter: any;
    public bookmarks: Array<BookmarkModel> = [];

    constructor(public dialog: MatDialog, private _favouritesService: FavouritesService, private _entryService: EntryService) {
        this.filter = null;
    }

    ngOnInit(): void {}

    public onFilterChanges(data: any): void {
        this.filter = data;
    }

    public openTagDialog(): void {
        let dialogRef = this.dialog.open(TagChangeComponent, {
            width: '450px',
            data: { projectId: this.filter.projectId }
        });

        dialogRef.afterClosed().subscribe(result => {});
    }

    public openBookmarksDialog(): void {
        let dialogRef = this.dialog.open(BookmarkChangeComponent, {
            width: '450px',
            data: { projectId: this.filter.projectId }
        });

        dialogRef.afterClosed().subscribe(result => {});
    }
}
