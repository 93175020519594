import { ToneType } from '../_core/_models/analytics.model';

export interface ITone {
  name: string;
  value: ToneType;
}

export const TONES_LIST: Array<ITone> = [
  { name: 'Позитивный', value: 'POSITIVE' },
  { name: 'Нейтральный', value: 'NEUTRAL' },
  { name: 'Негативный', value: 'NEGATIVE' },
];
