export const sidebarItems = [
    {
        icon: 'assets/icons/monitoring.svg',
        label: 'Мониторинг',
        link: 'monitoring'
    },
    {
        icon: 'assets/icons/projects.svg',
        label: 'Проекты',
        link: 'projects'
    },
    {
        icon: 'assets/icons/bookmark.svg',
        label: 'Избранные',
        link: 'bookmarks'
    },
    {
        icon: 'assets/icons/notification.svg',
        label: 'Уведомления',
        link: 'notification'
    },
    // {
    //     icon: 'assets/icons/report.svg',
    //     label: 'Отчеты',
    //     link: 'reports'
    // },
    {
        icon: 'assets/icons/analytics.svg',
        label: 'Отчеты',
        link: 'reports'
    },
    {
        icon: 'assets/icons/search.svg',
        label: 'Поиск',
        link: 'search'
    }
];
