import { Component, OnInit } from '@angular/core';
import { ISidebarItem } from '../../_models/sidebar-item.model';
import { sidebarItems } from './_sidebarItems';
import { AuthService } from '../../_services/auth.service';
import { MobileService } from '../../_services/mobile.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    public sidebarItems: Array<ISidebarItem> = sidebarItems;
    public isBurgerOpen: boolean = false;

    constructor(private authService: AuthService, private _mobileService: MobileService) {}

    ngOnInit(): void {
        this._mobileService.isBurgerOpenObservable$.subscribe(value => {
            this.isBurgerOpen = value;
        });
    }

    public logout() {
        this.authService.logout();
    }

    public closeBurger(): void {
        this._mobileService.isBurgerOpen = false;
    }
}
