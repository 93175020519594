import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { INotifierArgsRequest } from '../_models/_notifier/notifier-input.model';
import { INotifier } from '../_models/_notifier/notifier.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private _http: HttpClient) {}

  public getNotifications(): Observable<Array<INotifier>> {
    return this._http.get<Array<INotifier>>(`${environment.apiUrl}/notifiers`);
  }

  public saveNotifier(input: INotifierArgsRequest): Observable<any> {
    return this._http.post(`${environment.apiUrl}/notifiers`, { ...input });
  }

  public deleteNotifier(id: number): Observable<any> {
    return this._http.delete(`${environment.apiUrl}/notifiers/${id}`);
  }
}
