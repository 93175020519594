<div class="paginator">
    <div class="page" (click)="onLeftClick()">
        <img class="left" src="assets/icons/arrow-right.svg" alt="" />
    </div>
    <ng-container *ngFor="let p of [].constructor(totalCount); let i = index">
        <div
            class="page"
            *ngIf="i + 1 === 1 || i + 1 === totalCount || i === page || i + 2 === page || i + 1 === page"
            (click)="onPageClick(i)"
            [class.page_active]="i + 1 === page"
        >
            {{ i + 1 }}
        </div>
    </ng-container>

    <div class="page" (click)="onRightClick()">
        <img src="assets/icons/arrow-right.svg" alt="" />
    </div>
</div>
