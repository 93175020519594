import { Component, OnInit, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookmarkModel } from '../../_models/bookmark.model';
import { FavouritesService } from '../../_services/favourites.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-bookmark-change',
    templateUrl: './bookmark-change.component.html',
    styleUrls: ['./bookmark-change.component.scss']
})
export class BookmarkChangeComponent implements OnInit {
    public bookmarks: Array<BookmarkModel> = [];
    public formArray: FormArray;
    public newFormGroup: FormGroup;
    public projectId: number;
    public bookmarkId: number;

    public isAddLoading: boolean = false;
    public isEditLoading: boolean = false;
    public isDeleteLoading: boolean = false;
    public isDataLoading: boolean = false;

    constructor(
        private _favouritesService: FavouritesService,
        public dialogRef: MatDialogRef<BookmarkChangeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _fb: FormBuilder,
        private toastr: ToastrService
    ) {
        this.projectId = data.projectId;
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    ngOnInit(): void {
        this.isDataLoading = true;
        this.newFormGroup = this.genFormGroup();
        this._favouritesService._data$.subscribe({
            next: res => {
                this.bookmarks = res;
                this.formArray = this.genFormArray(this.bookmarks);
                this.isDataLoading = false;
            },
            error: () => (this.isDataLoading = false)
        });
        this._favouritesService.getAll().subscribe();
    }

    public genFormArray(list: Array<BookmarkModel>): FormArray {
        let fa: FormArray = this._fb.array([]);
        list.forEach(proj => {
            let fg: FormGroup = this.genFormGroup();
            fg.reset(proj);
            fa.push(fg);
        });
        fa.disable();
        return fa;
    }

    public change(fg: FormGroup): void {
        this.isEditLoading = true;
        let project = this.fgToModel(fg);
        fg.disable();
        this._favouritesService.update(project).subscribe({
            next: () => {
                this.isDeleteLoading = false;
                this.toastr.success('Избранное успешно изменёно ');
            },
            error: () => {
                this.isDeleteLoading = false;
                this.toastr.error('Ошибка, избранное не изменёно');
            }
        });
    }

    public delete(fg: FormGroup, i: number): void {
        if (!confirm('Вы уверены, что хотите удалить избранное? ')) {
            return;
        }
        this.isDeleteLoading = true;
        let project = this.fgToModel(fg);
        this._favouritesService.delete(project.id).subscribe({
            next: response => {
                this.formArray.removeAt(i);
                this.isDeleteLoading = false;
                this.toastr.success('Избранное успешно удалёно ');
            },
            error: () => {
                this.isDeleteLoading = false;
                this.toastr.error('Ошибка, избранное не удалёно');
            }
        });
    }

    public add(): void {
        this.isAddLoading = true;
        let entity = this.fgToModel(this.newFormGroup);
        this._favouritesService.create(entity).subscribe({
            next: response => {
                let fg = this.genFormGroup(response.id, response.name);
                fg.disable();
                this.formArray.insert(0, fg);
                this.newFormGroup = this.genFormGroup();
                this.isAddLoading = false;
                this.toastr.success('Избранное успешно добавлено ');
            },
            error: () => {
                this.isAddLoading = false;
                this.toastr.error('Ошибка, избранное не добавлено');
            }
        });
    }

    public genFormGroup(id?: number, name?: string): FormGroup {
        return this._fb.group({
            id: id ? id : null,
            name: name ? name : ''
        });
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    public fgToModel(fg: FormGroup): BookmarkModel {
        let obj;
        if (fg) {
            obj = fg.getRawValue();
        } else {
            obj = this.newFormGroup.getRawValue();
        }

        return {
            id: obj.id,
            name: obj.name
        };
    }
}
