import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { GeneralFilter } from 'src/app/_core/_models/filter-models/general-filter';
import { ReportsService } from 'src/app/_core/_services/reports.service';
import { FORMAT_FILES } from 'src/app/_helpers/_formatFiles';

@Component({
    selector: 'app-report-dialog',
    templateUrl: './report-dialog.component.html',
    styleUrls: ['./report-dialog.component.scss']
})
export class ReportDialogComponent implements OnInit {
    public isLoading: boolean;
    public form: FormGroup;
    public filter: GeneralFilter = {};
    public typeFiles = FORMAT_FILES;
    public chosenTypeFile: any;

    constructor(private _matDialog: MatDialog, private _reportsService: ReportsService, private _toastrService: ToastrService) {
        this._createForm();
    }

    ngOnInit(): void {
        this.form.valueChanges.subscribe(values => {
            this.filter = this._reportsService.getFilter();
            this.filter.email = values.email ? values.email : undefined;
            this._reportsService.setFilter(this.filter);
        });
    }
    public clickListener(item: any) {
        this.chosenTypeFile = item.value;
        this.form.controls['fileType'].setValue(item.value.type);
    }

    public sendReport(): void {
        this.isLoading = true;
        this._reportsService.getReports(this.filter).subscribe({
            next: respponse => {
                var blob = new Blob([respponse], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.download = `Отчёт_${
                    this.filter.startDate ? new Date(this.filter.startDate).toLocaleString('ru').split(' ')[0].slice(0, -1) : '_'
                }/${this.filter.endDate ? new Date(this.filter.endDate).toLocaleString('ru').split(' ')[0].slice(0, -1) : '_'}.xlsx`;
                link.href = window.URL.createObjectURL(blob);
                link.click();
                link.remove();
                setTimeout(() => URL.revokeObjectURL(link.href), 7000);
                this.isLoading = false;
                this._toastrService.success('Отчет отправлен на почту');
            }
        });
    }
    // public sendReport(): void {
    //     this.isLoading = true;
    //     this._reportsService.getReports(this.filter).subscribe({
    //         next: respponse => {
    //             this.isLoading = false;
    //             this._toastrService.success(respponse);
    //             this.closeDialog();
    //         }
    //     });
    // }

    public closeDialog(): void {
        this._matDialog.closeAll();
    }

    public _createForm(): void {
        this.form = new FormGroup({
            email: new FormControl('', [Validators.email, Validators.required]),
            fileType: new FormControl('', [])
        });
    }
}
