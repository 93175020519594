import { Component, Input, AfterViewInit } from '@angular/core';
import { ChartComponent } from '../chart/chart.component';

@Component({
    selector: 'app-pie-chart',
    templateUrl: './pie-chart.component.html',
    styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent extends ChartComponent {
    public apexLegend: any;
    public apexDataLabels: any;
    public apexChart: any;
    public apexStroke: any;

    private _isLoading: boolean;
    @Input() isSecondary: boolean;
    @Input() set isLoading(b: boolean) {
        this._isLoading = b;
    }

    public localColors: Array<string> = ['#FFF1E6', '#DBE7E4', '#C5DEDD', '#D6E2E9', '#BCD4E6', '#F0EFEB', '#99C1DE'];

    constructor() {
        super();
        this.apexLegend = {
            position: 'bottom',
            labels: {
                colors: ['#2A3540', '#2A3540'],
                innerWidth: '100px'
            },
            fontSize: '10px',
            fontFamily: 'GothamPro',
            height: 50
        };

        this.apexDataLabels = {
            formatter: (val: any, opts: any) => ''
        };

        this.apexChart = {
            width: '100%',
            height: 300,
            type: 'pie',
            events: {
                dataPointSelection: (event: any, chartContext: any, config: any) => {
                    this.dataClickEvent.emit(config.dataPointIndex);
                }
            },

            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            }
        };

        this.apexStroke = { show: false };
    }
}
