import { Component, OnInit } from '@angular/core';
import { ReportModel } from 'src/app/_core/_models/report.model';
import { Observable } from 'rxjs';
import { GeneralFilter } from 'src/app/_core/_models/filter-models/general-filter';
import { ProjectHoursService } from '../monitoring/_services/project-hours.service';
import { MonitoringCategoryService } from '../monitoring/_services/monitoring-category.service';
import { MonitoringSourceService } from '../monitoring/_services/monitoring-source.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TONE_ENUM } from 'src/app/_core/_components/sidebar-filter/sidebar-filter.component';
import { EntryService } from 'src/app/_core/_services/entry.service';
import { EntryModel } from 'src/app/_core/_models/entry.model';
import { trans } from 'src/app/_helpers/locales';
// import {trans}

@Component({
    selector: 'app-tones',
    templateUrl: './tones.component.html',
    styleUrls: ['./tones.component.scss']
})
export class TonesComponent implements OnInit {
    public entries: Array<EntryModel>;
    public projectHoursReport$: Observable<ReportModel>;
    public categoryReport: ReportModel;
    public sourceReport: ReportModel;
    public toneFilter: TONE_ENUM;
    public trans: any = trans;

    private _filter_cache: string;
    private _filter: GeneralFilter;
    public set filter(f: GeneralFilter) {
        this._filter = f;
        if (JSON.stringify(f) === this._filter_cache || Object.keys(f).length === 0) return;
        this._filter_cache = JSON.stringify(f);
        f && this.updateAllData();
    }
    public get filter(): GeneralFilter {
        return this._filter;
    }

    constructor(
        private _projectHoursService: ProjectHoursService,
        private _monitoringCategoryService: MonitoringCategoryService,
        private _monitoringSourceService: MonitoringSourceService,
        private _route: ActivatedRoute
    ) {
        // this.filter = {};
    }

    ngOnInit(): void {
        this.initSubscribers();
        let tone = this._route.snapshot.params['tone'];
        if (tone) {
            this.filter = { tone };
        }
        this.filter = {};
    }

    public categoryListener(labelIndex: number): void {
        this.filter.category = this.categoryReport.labels[labelIndex];
        this.filter = this.filter;
    }

    public sourceListener(labelIndex: number): void {
        this.filter.source = this.sourceReport.labels[labelIndex];
        this.filter = this.filter;
    }

    public filterListener(data: GeneralFilter): void {
        data.category = this.filter.category;
        data.source = this.filter.source;
        data.entryType = this.filter.entryType;
        data.tone = this.filter.tone;
        this.filter = data;
    }

    public updateAllData(): void {
        this._projectHoursService.get(this.filter).subscribe();
        this._monitoringCategoryService.get(this.filter).subscribe();
        this._monitoringSourceService.get(this.filter).subscribe();
    }

    public initSubscribers() {
        this.projectHoursReport$ = this._projectHoursService._data$;

        this._monitoringCategoryService._data$.subscribe(res => {
            if (res) {
                if (res.series.length === 0 && res.labels.length === 0) {
                    this.categoryReport = {
                        series: [1],
                        labels: ['нет данных']
                    };
                    return;
                }
            }
            this.categoryReport = res;
        });

        this._monitoringSourceService._data$.subscribe(res => {
            if (res) {
                if (res.series.length === 0 && res.labels.length === 0) {
                    this.sourceReport = {
                        series: [1],
                        labels: ['нет данных']
                    };
                    return;
                }
            }
            this.sourceReport = res;
        });
    }
}
