import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ILoginArgsRequest, ILoginResponse } from '../_models/_auth/auth.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private _http: HttpClient, private _router: Router) {}

    public login(input: ILoginArgsRequest): Observable<ILoginResponse> {
        return this._http.post<ILoginResponse>(`${environment.apiUrl}/auth/login`, {
            ...input
        });
    }

    public refreshToken(token: string): Observable<any> {
        return this._http.post(`${environment.apiUrl}/auth/refresh-token`, {
            refreshToken: token
        });
    }

    public logout(): void {
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
        this._router.navigate(['/login']);
    }

    public locationExistanceChecker(name: string): Observable<any> {
        return this._http.get(`${environment.apiUrl}/locations/checker/${name}`);
    }

    public authMe(): Observable<any> {
        return this._http.get(`${environment.apiUrl}/auth/me`);
    }
}
