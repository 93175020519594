import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './_core/_components/page-not-found/page-not-found.component';
import { AuthGuard } from './_core/_guards/auth.guard';
import { UnauthGuard } from './_core/_guards/unauth.guard';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('src/app/_modules/auth/auth.module').then(m => m.AuthModule),
        canActivate: [UnauthGuard]
    },
    {
        path: 'system',
        loadChildren: () => import('src/app/_modules/system/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: '404',
        component: PageNotFoundComponent
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: '404'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
