import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/_core/_guards/auth.guard';
import { SystemComponent } from './system.component';
import { AccountComponent } from './_components/account/account.component';
import { AnalyticsComponent } from './_components/analytics/analytics.component';
import { BookmarksComponent } from './_components/bookmarks/bookmarks.component';
import { CategorySourceComponent } from './_components/category-source/category-source.component';
import { MonitoringComponent } from './_components/monitoring/monitoring.component';
import { NotificationComponent } from './_components/notification/notification.component';
import { ProjectsComponent } from './_components/projects/projects.component';
import { ReportsComponent } from './_components/reports/reports.component';
import { SearchComponent } from './_components/search/search.component';
import { TonesComponent } from './_components/tones/tones.component';

const routes: Routes = [
    {
        path: '',
        component: SystemComponent,
        // canActivate: [AuthGuard],

        children: [
            {
                path: 'monitoring',
                component: MonitoringComponent
            },
            {
                path: 'projects',
                component: ProjectsComponent
            },
            {
                path: 'projects/:projectId',
                component: ProjectsComponent
            },
            {
                path: 'bookmarks',
                component: BookmarksComponent
            },
            {
                path: 'reports',
                component: AnalyticsComponent
            },
            {
                path: 'notification',
                component: NotificationComponent
            },
            // {
            //     path: 'reports',
            //     component: ReportsComponent
            // },
            {
                path: 'search',
                component: SearchComponent
            },
            {
                path: 'search',
                component: SearchComponent
            },
            {
                path: 'tones/:tone',
                component: TonesComponent
            },
            {
                path: 'categories/:category',
                component: CategorySourceComponent,
                pathMatch: 'full'
            },
            {
                path: 'sources/:source',
                component: CategorySourceComponent
            },
            { path: 'account', component: AccountComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SystemRoutingModule {}
