import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
    @Input() label: string;
    private _fc: FormControl;
    // @Input() default: Date;

    @Input()
    public set control(fc: AbstractControl) {
        this._fc = fc as FormControl;
        // console.log(fc.getRawValue());
        // this._fc.setValue(this.default);
    }

    @Input() theme: string;

    public get control(): FormControl {
        return this._fc;
    }

    constructor(private dateAdapter: NgxMatDateAdapter<any>) {}

    ngOnInit(): void {
        this.dateAdapter.setLocale('ru');
    }
}
