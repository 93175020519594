import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
    @Input() page: number;
    @Input() totalCount: number;
    @Input() size: number;
    @Output() pageListener: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {}

    onPageClick(i: number) {
        this.pageListener.emit(i + 1);
    }

    onLeftClick(): void {
        if (this.page > 1) {
            this.pageListener.emit(this.page - 1);
        }
    }

    onRightClick(): void {
        if (this.page < this.totalCount) {
            this.pageListener.emit(this.page + 1);
        }
    }
}
