<apx-chart
    [series]="chartOptions.series!"
    [chart]="apexChart"
    [xaxis]="chartOptions.xaxis!"
    [dataLabels]="chartOptions.dataLabels!"
    [grid]="chartOptions.grid!"
    [stroke]="chartOptions.stroke!"
    [title]="{ text: title }"
    [labels]="labels"
    #chart
></apx-chart>
