import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TagModel } from '../_models/tag-model';

@Injectable({
    providedIn: 'root'
})
export class TagService {
    private readonly _data;
    public _data$;

    private readonly _filteredData;
    public _filteredData$;

    constructor(private _http: HttpClient) {
        this._data = new Subject<TagModel[]>();
        this._data$ = this._data.asObservable();

        this._filteredData = new Subject<TagModel[]>();
        this._filteredData$ = this._data.asObservable();
    }

    private changeData(data: Array<TagModel>): void {
        this._data.next(data);
    }

    public getAll(projectId?: number): Observable<Array<TagModel>> {
        return this._http.get<Array<TagModel>>(`${environment.apiUrl}/tags`).pipe(
            map(res => {
                this.changeData(res);
                return res;
            })
        );
    }
    public getAllByLocation(projectId?: number): Observable<Array<TagModel>> {
        return this._http.get<Array<TagModel>>(`${environment.apiUrl}/tags/byLocation`).pipe(
            map(res => {
                this.changeData(res);
                return res;
            })
        );
    }

    public getByProjectId(projectId: number): Observable<Array<TagModel>> {
        return this._http.get<Array<TagModel>>(`${environment.apiUrl}/tags/projects/${projectId}`).pipe(
            map(res => {
                this.changeData(res);
                return res;
            })
        );
    }

    public getById(id: number): Observable<TagModel> {
        return this._http.get<TagModel>(`${environment.apiUrl}/tags/${id}`);
    }

    public create(tag: Partial<TagModel>): Observable<TagModel> {
        return this._http.post<TagModel>(`${environment.apiUrl}/tags`, tag);
    }

    public update(tag: TagModel): Observable<void> {
        return this._http.put<void>(`${environment.apiUrl}/tags/${tag.id}`, {
            ...tag
        });
    }

    public delete(id: number): Observable<void> {
        return this._http.delete<void>(`${environment.apiUrl}/tags/${id}`);
    }
}
