import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAnalyticsArgsRequest } from '../_models/analytics.model';
import { GeneralFilter } from '../_models/filter-models/general-filter';
import { ReportDto } from '../_models/report.model';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    constructor(private _http: HttpClient) {}

    public getTopSource(input: GeneralFilter): Observable<any> {
        let queryParams = new HttpParams();
        Object.keys(input).map(k => {
            if ((input as any)[k]) {
                queryParams = queryParams.append(k, (input as any)[k]);
            }
        });

        return this._http.get(`${environment.apiUrl}/analytics/top-source`, {
            params: queryParams
        });
    }

    public getTopSocialNetworks(input: GeneralFilter): Observable<any> {
        let queryParams = new HttpParams();
        Object.keys(input).map(k => {
            if ((input as any)[k] !== undefined) {
                queryParams = queryParams.append(k, (input as any)[k]);
            }
        });
        return this._http.get(`${environment.apiUrl}/analytics/top-source-by-social-networks`, { params: queryParams });
    }
    public getTopSourceByTone(input: GeneralFilter): Observable<any> {
        let queryParams = new HttpParams();
        Object.keys(input).map(k => {
            if ((input as any)[k] !== undefined) {
                queryParams = queryParams.append(k, (input as any)[k]);
            }
        });
        return this._http.get(`${environment.apiUrl}/analytics/top-source-by-tone`, { params: queryParams });
    }
    public getTopSourceDetailSocialNetwork(input: GeneralFilter): Observable<any> {
        let queryParams = new HttpParams();
        Object.keys(input).map(k => {
            if ((input as any)[k] !== undefined) {
                queryParams = queryParams.append(k, (input as any)[k]);
            }
        });
        return this._http.get(`${environment.apiUrl}/analytics/top-source-by-detail-social-networks`, { params: queryParams });
    }

    public getEntriesTone(input: GeneralFilter): Observable<ReportDto[]> {
        let queryParams = new HttpParams();
        Object.keys(input).map(k => {
            if ((input as any)[k] !== undefined) {
                queryParams = queryParams.append(k, (input as any)[k]);
            }
        });
        return this._http.get<ReportDto[]>(`${environment.apiUrl}/analytics/grouped-tones`, { params: queryParams });
    }

    public getEntriesCategories(input: GeneralFilter): Observable<any> {
        let queryParams = new HttpParams();

        Object.keys(input).map(k => {
            if ((input as any)[k] !== undefined) {
                queryParams = queryParams.append(k, (input as any)[k]);
            }
        });
        return this._http.get(`${environment.apiUrl}/analytics/categories`, {
            params: queryParams
        });
    }

    public getEntriesGroupedTodayByHours(input: GeneralFilter): Observable<ReportDto[]> {
        let queryParams = new HttpParams();
        Object.keys(input).map(k => {
            if ((input as any)[k] !== undefined) {
                queryParams = queryParams.append(k, (input as any)[k]);
            }
        });
        return this._http.get<ReportDto[]>(`${environment.apiUrl}/analytics/grouped-todays-hours`, { params: queryParams });
    }

    public getToneCoefficientByHour(input: GeneralFilter): Observable<ReportDto[]> {
        let queryParams = new HttpParams();
        Object.keys(input).map(k => {
            if ((input as any)[k] !== undefined) {
                queryParams = queryParams.append(k, (input as any)[k]);
            }
        });
        return this._http.get<ReportDto[]>(`${environment.apiUrl}/analytics/grouped-tone-todays-hours`, { params: queryParams });
    }

    public getEntriesGroupedByDay(input: GeneralFilter): Observable<ReportDto[]> {
        let queryParams = new HttpParams();
        Object.keys(input).map(k => {
            if ((input as any)[k] !== undefined) {
                queryParams = queryParams.append(k, (input as any)[k]);
            }
        });
        return this._http.get<ReportDto[]>(`${environment.apiUrl}/analytics/grouped-days`, { params: queryParams });
    }
}
