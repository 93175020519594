<ng-container *ngFor="let item of filters">
    <ng-container *ngTemplateOutlet="{startDate, endDate, projectId, bookmarkId, sourceType, entryType, tone}[item]"></ng-container>
</ng-container>
<ng-content></ng-content>
<ng-container *ngFor="let item of filters">
    <ng-container *ngTemplateOutlet="{source}[item]"></ng-container>
</ng-container>

<ng-container *ngIf="sourceTypes"></ng-container>

<ng-template #bookmarkId>
    <ng-container *ngIf="bookmarks">
        <div class="filter__item">
            <app-odropdown
                label="Выберите избранное"
                theme="white"
                [list]="bookmarks"
                fieldName="name"
                (clickEvent)="checkBookmark($event)"
                [changeableTitle]="true"
            ></app-odropdown>
        </div>
    </ng-container>
</ng-template>

<!--  -->
<ng-template #projectId>
    <ng-container *ngIf="projectList">
        <div class="filter__item">
            <app-odropdown
                label="Выберите проект"
                theme="white"
                [list]="projectNameList"
                (clickEvent)="checkProject($event)"
                [changeableTitle]="true"
                [currentValue]="currentProjectName"
            ></app-odropdown>
        </div>
    </ng-container>
</ng-template>
<!--  -->
<ng-template #sourceType>
    <div class="filter__item">
        <app-odropdown
            label="Тип источника"
            theme="white"
            [list]="sourceTypes"
            (clickEvent)="checkSourceType($event)"
            [changeableTitle]="true"
        ></app-odropdown>
    </div>
</ng-template>
<!--  -->
<ng-template #entryType>
    <div class="filter__item">
        <app-odropdown
            label="Тип отметки"
            theme="white"
            [list]="entryTypes"
            (clickEvent)="checkEntryType($event)"
            [changeableTitle]="true"
        ></app-odropdown>
    </div>
</ng-template>
<!--  -->
<ng-template #tone>
    <div class="filter__item">
        <app-odropdown
            label="Тональность"
            theme="white"
            [list]="toneTypes"
            (clickEvent)="checkTone($event)"
            [changeableTitle]="true"
        ></app-odropdown>
    </div>
</ng-template>
<!--  -->
<ng-template #startDate>
    <div class="filter__item start-date">
        <ng-container *ngIf="formGroup.get('startDate')">
            <app-date-picker [control]="formGroup.get('startDate')" label="Дата начала"></app-date-picker>
        </ng-container>
    </div>
</ng-template>
<!--  -->
<ng-template #endDate>
    <div class="filter__item end-date">
        <ng-container *ngIf="formGroup.get('endDate')">
            <app-date-picker [control]="formGroup.get('endDate')" label="Дата окончания"></app-date-picker>
        </ng-container>
    </div>
</ng-template>

<!--  -->
<ng-template #source>
    <div class="filter__item">
        <div class="socials">
            <ng-container *ngFor="let item of socials">
                <div class="social" (click)="checkSocial(item.name)">
                    <div class="filter__icon">
                        <img [src]="item.iconUrl" [alt]="item.name" />
                        <div>{{ item.count }}</div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>
