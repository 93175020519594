import { Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { GeneralFilter } from 'src/app/_core/_models/filter-models/general-filter';
import { ReportDto } from 'src/app/_core/_models/report.model';
import { MonitoringService } from 'src/app/_core/_services/monitoring.service';

@Injectable({
    providedIn: 'root'
})
export class MonitoringToneService extends MonitoringService {
    protected readonly _dataObj: any;
    public __dataObj$: Observable<any>;

    constructor() {
        super();
        this._dataObj = new Subject<any>();
        this.__dataObj$ = this._dataObj.asObservable();
    }

    private changeTones(data: ReportDto[]) {
        let q: any = {
            positiveCount: data.find(f => f.name === 'POSITIVE')?.count || 0,
            neutralCount: data.find(f => f.name === 'NEUTRAL')?.count || 0,
            negativeCount: data.find(f => f.name === 'NEGATIVE')?.count || 0
        };
        this._dataObj.next(q);
    }

    public get(filter: GeneralFilter): Observable<ReportDto[]> {
        this._dataObj.next(null);
        return super.callGet('/analytics/grouped-tones', filter).pipe(
            map(res => {
                this.changeTones(res);
                this.changeData(res);
                return res;
            })
        );
    }
}
