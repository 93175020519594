<div class="entries">
    <ng-container *ngFor="let item of entries">
        <app-card-main [entry]="item" [tags]="tags"></app-card-main>
    </ng-container>
</div>
<div class="load-more__wrapper">
    <ng-container *ngIf="!(isLoadind$ | async)">
        <button class="btn btn-primary" (click)="loadMore()">Загрузить ещё</button>
    </ng-container>
    <ng-container *ngIf="isLoadind$ | async">
        <button class="btn btn-primary loading">
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </button>
    </ng-container>
</div>
