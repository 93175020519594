import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SystemRoutingModule } from './system-routing.module';
import { SystemComponent } from './system.component';
import { MonitoringComponent } from './_components/monitoring/monitoring.component';
import { ProjectsComponent } from './_components/projects/projects.component';
import { BookmarksComponent } from './_components/bookmarks/bookmarks.component';
import { AnalyticsComponent } from './_components/analytics/analytics.component';
import { NotificationComponent } from './_components/notification/notification.component';
import { ReportsComponent } from './_components/reports/reports.component';
import { AccountComponent } from './_components/account/account.component';
import { CoreModule } from 'src/app/_core/core.module';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { TonesComponent } from './_components/tones/tones.component';
import { CategorySourceComponent } from './_components/category-source/category-source.component';
import { SearchComponent } from './_components/search/search.component';
import { ReportDialogComponent } from './_components/analytics/_components/report-dialog/report-dialog.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DownloadReportDialogComponent } from './_components/analytics/_components/download-report-dialog/download-report-dialog.component';

@NgModule({
    declarations: [
        SystemComponent,
        MonitoringComponent,
        ProjectsComponent,
        BookmarksComponent,
        AnalyticsComponent,
        NotificationComponent,
        ReportsComponent,
        AccountComponent,
        TonesComponent,
        CategorySourceComponent,
        SearchComponent,
        ReportDialogComponent,
        DownloadReportDialogComponent
    ],
    imports: [CommonModule, CoreModule, SystemRoutingModule, MatDialogModule, ReactiveFormsModule, MatPaginatorModule],
    providers: []
})
export class SystemModule {}
