import { Component, OnInit, Input } from '@angular/core';
import { EntryModel } from '../../_models/entry.model';
import { GeneralFilter } from '../../_models/filter-models/general-filter';
import { EntryService } from '../../_services/entry.service';
import { map, Observable } from 'rxjs';

@Component({
    selector: 'app-entry-list',
    templateUrl: './entry-list.component.html',
    styleUrls: ['./entry-list.component.scss']
})
export class EntryListComponent implements OnInit {
    private _filter: GeneralFilter;

    @Input() tags?: Array<string>;
    @Input() public set filter(f: GeneralFilter) {
        if (!f) return;
        if (this._filter) {
            f.limit = this._filter.limit || 20;
            f.page = this._filter.page || 1;
        } else {
            f.limit = 20;
            f.page = 1;
        }
        this._filter = f;
        f && this.updateAllData();
    }
    public get filter(): GeneralFilter {
        return this._filter;
    }
    public entries: Array<EntryModel> = [];
    public isLoading: boolean = false;
    public isLoadind$: Observable<boolean>;

    constructor(private _entryService: EntryService) {}

    ngOnInit(): void {
        this.initSubscribers();
    }

    public updateAllData(): void {
        this.isLoading = true;
        this._entryService.search(this.filter).subscribe({
            next: () => (this.isLoading = false),
            error: () => (this.isLoading = false)
        });
        this.isLoadind$ = this._entryService.isLoading$;
    }

    public initSubscribers() {
        this._entryService._data$.subscribe((res: Array<EntryModel>) => {
            if (!res) return;
            if (this.filter.query && this.filter.query !== '') {
                res = res.map(item => {
                    item.text = this.highlightText(this.filter.query!, item.text);
                    return item;
                });
            }

            if (this.filter.page! > 1) {
                this.entries = [...this.entries, ...res];
            }

            this.entries = res;
        });
    }

    private highlightText(searchQuery: string, text: string): string {
        if (!searchQuery) return text;
        const re = new RegExp(searchQuery, 'igm');
        return text.replace(re, '<span class="highlighted-text">$&</span>');
    }

    public loadMore() {
        this.filter.page!++;
        this.updateAllData();
    }
}
