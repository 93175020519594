import { Component, OnInit, Output, Input, EventEmitter, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BookmarkModel } from '../../_models/bookmark.model';
import { ProjectModel } from '../../_models/project-model';
import { ReportDto } from '../../_models/report.model';
import { FavouritesService } from '../../_services/favourites.service';
import { ProjectService } from '../../_services/project.service';
import { SourceReportService } from '../../_services/source-report.service';

@Component({
    selector: 'app-sidebar-filter',
    templateUrl: './sidebar-filter.component.html',
    styleUrls: ['./sidebar-filter.component.scss']
})
export class SidebarFilterComponent implements OnInit, OnChanges {
    public formGroup: FormGroup;
    public projectList: Array<ProjectModel>;
    public projectNameList: string[];
    public bookmarks: BookmarkModel[];
    public socials: any[];
    public sourceTypes = Object.values(SOURCE_TYPE_ENUM);
    public entryTypes = Object.values(ENTRY_TYPE_ENUM);
    public toneTypes = Object.values(TONE_ENUM);

    private _filters: string[];
    @Input()
    public set filters(f: string[]) {
        this._filters = f;

        if (f.indexOf('source') > -1 && this.formGroup) {
            this._sourceService._data$.subscribe((res: ReportDto[]) => {
                if (!res) return;
                this.socials.forEach(soc => {
                    let social: any = res.find(s => s.name === soc.name);
                    soc.count = social ? social.count : 0;
                });
            });
            this._sourceService.get(this.formGroup.getRawValue()).subscribe();
        }

        if (f.indexOf('bookmarkId') > -1) {
            this._favouritesService._data$.subscribe(res => {
                this.bookmarks = res;
            });
            this._favouritesService.getAll().subscribe();
        }
    }
    public get filters(): string[] {
        return this._filters;
    }

    private _projectId: number;
    @Input() set projectId(p: number) {
        this.formGroup.controls['projectId'].setValue(p);
        this._projectId = p;
    }
    get projectId(): number {
        return Number(this._projectId);
    }
    private currentProjectName: string | null = null;

    @Input() isStartDateToday?: boolean;
    @Input() selectedStartDate?: Date;

    @Output()
    filterChangeEvent = new EventEmitter<number>();

    constructor(
        private _fb: FormBuilder,
        private _projectService: ProjectService,
        private _sourceService: SourceReportService,
        private _favouritesService: FavouritesService
    ) {
        this.formGroup = this.genFormGroup();

        this.socials = socials;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.ngOnInit();
    }

    ngOnInit(): void {
        this.formGroup.valueChanges.subscribe(data => {
            if (typeof data.startDate !== 'string') {
                data.startDate = data.startDate ? (data.startDate as Date)?.toISOString() : null;
                data.endDate = data.endDate ? (data.endDate as Date)?.toISOString() : null;
            }

            if (this.filters.indexOf('source') > -1 && data) {
                this._sourceService.get(data).subscribe();
            }

            this.filterChangeEvent.emit(data);
        });

        let today = new Date();

        if (this.isStartDateToday && !this.selectedStartDate) {
            this.formGroup.get('startDate')?.setValue(new Date(today.getUTCFullYear(), today.getMonth(), today.getDate() - 7, 0, 0, 0));
        }
        if (this.selectedStartDate) {
            this.formGroup.get('startDate')?.setValue(this.selectedStartDate);
        }
        if (!this.selectedStartDate && !this.isStartDateToday) {
            this.formGroup.get('startDate')?.setValue(undefined);
        }
        this._projectService.getAll().subscribe(res => {
            this.projectList = res;
            this.projectNameList = res.map((item: any) => item.name);

            this.currentProjectName = this.projectList.find(item => item.id === this.projectId)?.name || null;
        });
    }

    public genFormGroup(): FormGroup {
        return this._fb.group({
            bookmarkId: new FormControl(),
            projectId: new FormControl(),
            startDate: new FormControl(),
            endDate: new FormControl(),
            sourceType: new FormControl(),
            entryType: new FormControl(),
            source: new FormControl(),
            tone: new FormControl()
        });
    }

    public checkProject(projectName: string) {
        let el;

        if (projectName) el = this.projectList.find(el => el.name === projectName);

        this.formGroup.controls['projectId'].setValue(el?.id);
    }

    public checkSocial(source: string): void {
        this.formGroup.controls['source'].setValue(source);
    }

    public checkSourceType(source: string): void {
        const indexOfS = Object.values(SOURCE_TYPE_ENUM).indexOf(source as unknown as SOURCE_TYPE_ENUM);
        const key = Object.keys(SOURCE_TYPE_ENUM)[indexOfS];
        this.formGroup.controls['sourceType'].setValue(key);
    }

    public checkEntryType(entry: string): void {
        const indexOfS = Object.values(ENTRY_TYPE_ENUM).indexOf(entry as unknown as ENTRY_TYPE_ENUM);
        const key = Object.keys(ENTRY_TYPE_ENUM)[indexOfS];
        this.formGroup.controls['entryType'].setValue(key);
    }

    public checkTone(tone: string): void {
        const indexOfS = Object.values(TONE_ENUM).indexOf(tone as unknown as TONE_ENUM);
        const key = Object.keys(TONE_ENUM)[indexOfS];
        this.formGroup.controls['tone'].setValue(key);
    }

    public checkBookmark(b: BookmarkModel): void {
        this.formGroup.controls['bookmarkId'].setValue(b.id);
    }
}

export interface FilterModel {
    projectId?: number;
    startDate?: string;
    endDate?: string;
    sourceType?: string;
    entryType?: string;
    source?: string;
    tone?: string;
}

export enum SOURCE_TYPE_ENUM {
    NEWS = 'Новости',
    SOCIAL = 'Социальные сети'
}

export enum ENTRY_TYPE_ENUM {
    NEWS = 'Новости',
    COMMENTS = 'Комментарии'
}

export enum TONE_ENUM {
    NEGATIVE = 'Негативный',
    POSITIVE = 'Позитивный',
    NEUTRAL = 'Нейтральный'
}

export const socials = [
    {
        name: 'facebook',
        iconUrl: 'assets/icons/facebook.svg'
    },
    {
        name: 'twitter',
        iconUrl: 'assets/icons/twitter.svg'
    },
    {
        name: 'telegram',
        iconUrl: 'assets/icons/telegram_filter.svg'
    },
    {
        name: 'vk',
        iconUrl: 'assets/icons/vk.svg'
    },
    {
        name: 'instagram',
        iconUrl: 'assets/icons/instagram.svg'
    },
    {
        name: 'youtube',
        iconUrl: 'assets/icons/youtube.svg'
    },
    {
        name: 'tiktok',
        iconUrl: 'assets/icons/tiktok.svg'
    }
];
