import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgApexchartsModule } from 'ng-apexcharts';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './_components/header/header.component';
import { SidebarComponent } from './_components/sidebar/sidebar.component';
import { SidebarItemComponent } from './_components/sidebar-item/sidebar-item.component';
import { DatePickerComponent } from './_components/date-picker/date-picker.component';
import { MenuItemComponent } from './_components/menu-item/menu-item.component';
import { CardMarkComponent } from './_components/card-mark/card-mark.component';
import { CardMainComponent } from './_components/card-main/card-main.component';
import { DropdownComponent } from './_components/dropdown/dropdown.component';
import { MatSelectModule } from '@angular/material/select';

import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { CardSocialNetworkComponent } from './_components/card-social-network/card-social-network.component';
import { RightSidebarComponent } from './_components/right-sidebar/right-sidebar.component';
import { ChartComponent } from './_components/_charts/chart/chart.component';
import { PieChartComponent } from './_components/_charts/pie-chart/pie-chart.component';
import { LineChartComponent } from './_components/_charts/line-chart/line-chart.component';
import { BarChartComponent } from './_components/_charts/bar-chart/bar-chart.component';
import { AreaChartComponent } from './_components/_charts/area-chart/area-chart.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageNotFoundComponent } from './_components/page-not-found/page-not-found.component';
import { ProjectChangeComponent } from './_components/_project/project-change/project-change.component';
import { SidebarFilterComponent } from './_components/sidebar-filter/sidebar-filter.component';
import { TagChangeComponent } from './_components/tag-change/tag-change.component';
import { OdropdownComponent } from './_components/odropdown/odropdown.component';
import { BookmarkChangeComponent } from './_components/bookmark-change/bookmark-change.component';
import { LoaderComponent } from './_components/loader/loader.component';
import { CategoryPieChartComponent } from './_components/_charts/category-pie-chart/category-pie-chart.component';
import { SourcePieChartComponent } from './_components/_charts/source-pie-chart/source-pie-chart.component';
import { EntryListComponent } from './_components/entry-list/entry-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    NgxMatDateAdapter,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { SkeletonComponent } from './_components/skeleton/skeleton.component';
import { PaginatorComponent } from './_components/paginator/paginator.component';
import { DirectivesModule } from './_directives/directives.module';
import { SafeHtmlPipe } from './_pipes/safe-html.pipe';

@NgModule({
    declarations: [
        HeaderComponent,
        SidebarComponent,
        SidebarItemComponent,
        DatePickerComponent,
        MenuItemComponent,
        CardMarkComponent,
        CardMainComponent,
        DropdownComponent,
        CardSocialNetworkComponent,
        RightSidebarComponent,
        ChartComponent,
        PieChartComponent,
        LineChartComponent,
        BarChartComponent,
        AreaChartComponent,
        PageNotFoundComponent,
        ProjectChangeComponent,
        SidebarFilterComponent,
        TagChangeComponent,
        OdropdownComponent,
        BookmarkChangeComponent,
        LoaderComponent,
        CategoryPieChartComponent,
        SourcePieChartComponent,
        EntryListComponent,
        SkeletonComponent,
        PaginatorComponent,
        SafeHtmlPipe
    ],
    exports: [
        HeaderComponent,
        SidebarComponent,
        DatePickerComponent,
        MenuItemComponent,
        CardMarkComponent,
        CardMainComponent,
        DropdownComponent,
        ChartComponent,
        CardSocialNetworkComponent,
        RightSidebarComponent,
        PieChartComponent,
        LineChartComponent,
        BarChartComponent,
        AreaChartComponent,
        SidebarFilterComponent,
        PageNotFoundComponent,
        OdropdownComponent,
        LoaderComponent,
        CategoryPieChartComponent,
        SourcePieChartComponent,
        EntryListComponent,
        SkeletonComponent,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        EntryListComponent,
        PaginatorComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule,
        MatNativeDateModule,
        MatDatepickerModule,
        NgApexchartsModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        NgxMatTimepickerModule,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        DirectivesModule
    ],
    providers: []
})
export class CoreModule {}
