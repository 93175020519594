import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApexOptions, ChartComponent } from 'ng-apexcharts';
import { ApexChartCustom } from 'src/app/_core/_models/_apex-charts/apex-chart-custom.model';
import { IChartOptions } from '../../../_models/chart.model';

@Component({
    selector: 'app-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {
    @ViewChild('chart') chart: ChartComponent;

    @Input() title: string;

    private _series: number[];
    private readonly newProperty = 'Сохранить в SVG';

    @Input()
    public set series(data: number[]) {
        setTimeout(() => {
            this.chartOptions.series = [{ data, name: this.lineLabel }];
            this.chart.updateOptions(data);
        }, 0);
        this._series = data;
    }
    public get series(): number[] {
        return this._series;
    }

    private _labels: string[];
    @Input()
    public set labels(data: string[]) {
        setTimeout(() => {
            this.chartOptions.xaxis!.categories = data;
            this.chart.updateOptions(data);
        }, 0);
        this._labels = data;
    }
    public get labels(): string[] {
        return this._labels;
    }

    @Input() lineLabel?: string;

    public chartOptions: ApexOptions = {
        series: [
            {
                name: '',
                data: []
            }
        ],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        title: {
            text: '',
            align: 'left',
            style: {
                fontWeight: 'bold'
            }
        },
        grid: {
            row: {
                opacity: 0.5
            }
        },
        xaxis: {
            categories: []
        }
    };

    public apexChart: ApexChartCustom = {
        width: '100%',
        height: 300,
        type: 'line',
        zoom: {
            enabled: true
        },
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        },
        toolbar: {
            tools: {
                download: true
            }
        },
        defaultLocale: 'ru',
        locales: [
            {
                name: 'ru',
                options: {
                    months: [
                        'Январь',
                        'Февраль',
                        'Март',
                        'Апрель',
                        'Май',
                        'Июнь',
                        'Июль',
                        'Август',
                        'Сентябрь',
                        'Октябрь',
                        'Ноябрь',
                        'Декабрь'
                    ],
                    shortMonths: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
                    days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
                    shortDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                    toolbar: {
                        exportToSVG: 'Сохранить SVG',
                        exportToPNG: 'Сохранить PNG',
                        exportToCSV: 'Сохранить CSV',
                        selection: 'Выбор',
                        selectionZoom: 'Выбор с увеличением',
                        zoomIn: 'Увеличить',
                        zoomOut: 'Уменьшить',
                        pan: 'Перемещение',
                        reset: 'Сбросить увеличение'
                    }
                }
            }
        ]
    };

    constructor() {}

    ngOnInit(): void {}
}
