import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_core/_services/auth.service';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
    constructor(private _authService: AuthService) {}

    public email: string;
    public location: string;

    ngOnInit(): void {
        this._authService.authMe().subscribe(response => {
            this.email = response.email;
            this.location = response.location;
        });
    }
}
