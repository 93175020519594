import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntryModel } from 'src/app/_core/_models/entry.model';
import { GeneralFilter } from 'src/app/_core/_models/filter-models/general-filter';
import { ReportModel } from 'src/app/_core/_models/report.model';
import { EntryService } from 'src/app/_core/_services/entry.service';
import { MonitoringCategoryService } from '../monitoring/_services/monitoring-category.service';
import { MonitoringSourceService } from '../monitoring/_services/monitoring-source.service';

@Component({
    selector: 'app-category-source',
    templateUrl: './category-source.component.html',
    styleUrls: ['./category-source.component.scss']
})
export class CategorySourceComponent implements OnInit {
    public entries: Array<EntryModel>;
    public categoryReport: ReportModel;
    public sourceReport: ReportModel;
    private _filter: GeneralFilter;
    public set filter(f: GeneralFilter) {
        this._filter = f;
        f && this.updateAllData();
    }
    public get filter(): GeneralFilter {
        return this._filter;
    }

    private filterCategory: any;
    private filterSource: any;

    constructor(
        private _monitoringCategoryService: MonitoringCategoryService,
        private _monitoringSourceService: MonitoringSourceService,
        private _route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.initSubscribers();

        this.filterCategory = this._route.snapshot.params['category'] || null;
        this.filterSource = this._route.snapshot.params['source'] || null;
    }

    public categoryListener(labelIndex: number): void {
        this.filter.category = this.categoryReport.labels[labelIndex];
        this.filter = Object.assign({}, this.filter, { pageNum: undefined, limit: undefined });
    }

    public sourceListener(labelIndex: number): void {
        this.filter.source = this.sourceReport.labels[labelIndex];
        this.filter = Object.assign({}, this.filter, { pageNum: undefined, limit: undefined });
    }

    public filterListener(data: GeneralFilter): void {
        if (this.filter) {
            data.category = this.filter.category;
            data.source = this.filter.source;
            data.entryType = this.filter.entryType;
        } else {
            data.category = this.filterCategory;
            data.source = this.filterSource;
        }
        this.filter = data;
    }

    public updateAllData(): void {
        this._monitoringCategoryService.get(this.filter).subscribe();
        this._monitoringSourceService.get(this.filter).subscribe();
    }

    public initSubscribers() {
        this._monitoringCategoryService._data$.subscribe(res => {
            this.categoryReport = res;
        });

        this._monitoringSourceService._data$.subscribe(res => {
            this.sourceReport = res;
        });
    }
}
