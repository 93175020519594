import { outputAst } from '@angular/compiler';
import { Component, OnInit, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ChartComponent } from '../chart/chart.component';

@Component({
    selector: 'app-category-pie-chart',
    templateUrl: './category-pie-chart.component.html',
    styleUrls: ['./category-pie-chart.component.scss']
})
export class CategoryPieChartComponent implements OnInit {
    @Input() title: string;
    @Input() colors: string[];
    @Input() series: number[];
    @Input() labels: string[];

    @Output() sevent = new EventEmitter();

    public dummySeries = [1];
    public dummyLables = ['Нет данных'];
    constructor(private _router: Router, private zone: NgZone) {}

    public clickEventListener(labelInd: number): void {
        this.zone.run(() => {
            this._router.navigate(['/system/categories', this.labels[labelInd]]);
        });
    }

    ngOnInit(): void {}
}
