<div class="dropdown">
    <mat-form-field appearance="none" class="mat-form-field-wrapper">
        <mat-label class="label" [class.label_secondary]="isSecondary">{{ label }}</mat-label>
        <mat-select (selectionChange)="clickListener($event)" [class.secondary]="isSecondary" panelClass="dropdown__select" [value]="value">
            <mat-option panel [value]="undefined">
                <span class="option_clear"> Сбросить </span>
            </mat-option>
            <mat-option class="option" *ngFor="let item of list" [value]="item">
                {{ item.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
