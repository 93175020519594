import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TONE_ENUM } from '../_components/sidebar-filter/sidebar-filter.component';
import { EntryModel } from '../_models/entry.model';
import { GeneralFilter } from '../_models/filter-models/general-filter';
import { MonitoringService } from './monitoring.service';

@Injectable({
    providedIn: 'root'
})
export class EntryService extends MonitoringService {
    constructor() {
        super();
    }

    public getFavourites(): Observable<any> {
        return this._http.get(`${environment.apiUrl}`);
    }

    public update(entry: EntryModel): Observable<void> {
        return this._http.put<void>(`${environment.apiUrl}/entries/${entry.id}`, entry);
    }

    public updateTone(id: number, tone: TONE_ENUM): Observable<any> {
        let queryParams: HttpParams = new HttpParams();
        queryParams = queryParams.append('tone', tone);
        return this._http.put<any>(`${environment.apiUrl}/entries/update-tone/${id}`, null, {
            params: queryParams,
            //@ts-ignore
            responseType: 'text'
        });
    }

    public search(filter: GeneralFilter): Observable<any> {
        return super.callGet('/search', filter).pipe(
            map(res => {
                super.isLoading = false;
                let entryList: EntryModel[] = res?.items;
                super.changeData(entryList, true);
                return res;
            })
        );
    }
}
