import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TagChangeComponent } from 'src/app/_core/_components/tag-change/tag-change.component';
import { ProjectChangeComponent } from 'src/app/_core/_components/_project/project-change/project-change.component';
import { EntryModel } from 'src/app/_core/_models/entry.model';
import { GeneralFilter } from 'src/app/_core/_models/filter-models/general-filter';
import { ReportModel } from 'src/app/_core/_models/report.model';
import { EntryService } from 'src/app/_core/_services/entry.service';
import { ProjectDaysService } from '../monitoring/_services/project-days.service';
import { MonitoringToneModel } from '../monitoring/_models/monitoring-tone.model';
import { MonitoringToneService } from '../monitoring/_services/monitoring-tone.service';
import { Observable, of, map } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TONE_ENUM } from 'src/app/_core/_components/sidebar-filter/sidebar-filter.component';
import { TagService } from 'src/app/_core/_services/tag.service';

@Component({
    selector: 'app-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
    public entries: Array<EntryModel>;
    public projectDaysReport$: Observable<ReportModel> | null;
    private _filter_cache: string;
    private _filter: GeneralFilter = {};
    public set filter(f: GeneralFilter) {
        this._filter = f;

        if (JSON.stringify(f) === this._filter_cache) return;
        this._filter_cache = JSON.stringify(f);

        f && this.updateAllData();
    }
    public get filter(): GeneralFilter {
        return this._filter;
    }

    public tones$: Observable<any>;
    public tonesReport$: Observable<MonitoringToneModel>;

    public projectId: number;

    public locationTags: Array<string> = [];
    constructor(
        public dialog: MatDialog,
        private _projectDaysService: ProjectDaysService,
        private _monitoringToneService: MonitoringToneService,
        private _route: ActivatedRoute,
        public _router: Router,
        private _tagService: TagService
    ) {}

    ngOnInit(): void {
        this.initSubscribers();

        this.projectId = this._route.snapshot.params['projectId'] || null;

        this._tagService.getAllByLocation().subscribe({ next: response => (this.locationTags = response.map(tag => tag.name)) });
    }

    public filterListener(data: GeneralFilter): void {
        if (this.filter) {
            data.category = this.filter.category;
            data.source = this.filter.source;
            data.projectId = data.projectId || this.projectId;
        } else {
            data.projectId = this.projectId;
        }
        this.projectId = data.projectId;
        this.filter = data;
    }

    public openProjectDialog(): void {
        let dialogRef = this.dialog.open(ProjectChangeComponent, {
            width: '450px'
        });

        dialogRef.afterClosed().subscribe(result => {});
    }

    public openTagDialog(): void {
        let dialogRef = this.dialog.open(TagChangeComponent, {
            width: '450px',
            data: { projectId: this.filter.projectId }
        });

        dialogRef.afterClosed().subscribe(result => {});
    }

    public updateAllData(): void {
        this._projectDaysService.get(this.filter).subscribe();
        this._monitoringToneService.get(this.filter).subscribe();
    }

    public initSubscribers() {
        this.projectDaysReport$ = this._projectDaysService._data$;

        this.tones$ = this._monitoringToneService.__dataObj$.pipe(
            map(res => {
                if (!res) return res;
                let formatted = {
                    negativeCount: this.formatNumberToStringWithSpaces(res.negativeCount),
                    neutralCount: this.formatNumberToStringWithSpaces(res.neutralCount),
                    positiveCount: this.formatNumberToStringWithSpaces(res.positiveCount)
                };
                return formatted;
            })
        );
    }

    private formatNumberToStringWithSpaces(n: number): string {
        return String(n).replace(/(?!^)(?=(?:\d{3})+$)/g, ' ');
    }

    public navigateTOnes(tone: TONE_ENUM): void {
        this._router.navigate(['system/tones', tone]);
    }
}
