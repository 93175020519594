import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import { BookmarkModel } from '../../_models/bookmark.model';

@Component({
    selector: 'app-odropdown',
    templateUrl: './odropdown.component.html',
    styleUrls: ['./odropdown.component.scss']
})
export class OdropdownComponent implements OnInit {
    @Input() label: string;
    @Input() list: any[] = ['Один', 'dasdas', 'weqwe'];
    @Input() fieldName: string;
    @Input() changeableTitle = false;
    @Input() theme = 'default';
    @Input() multi: boolean = false;
    @Input() selectedList: any[] = [];

    @Output() clickEvent = new EventEmitter();
    @Output() openEvent = new EventEmitter();

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
        this.isShowList = false;
    }

    @HostListener('document:mousedown', ['$event'])
    onGlobalClick(event: Event): void {
        if (!this.hostRef.nativeElement.contains(event.target)) {
            this.isShowList = false;
        }
    }

    currentItem = null;
    @Input() set currentValue(data: any) {
        this.currentItem = data;
    }
    public isShowList: boolean;
    public formArray: FormArray;

    constructor(private hostRef: ElementRef) {}

    ngOnInit(): void {}

    public toggleIsShowList(): void {
        this.isShowList = !this.isShowList;
        this.isShowList && this.openEvent.emit();
    }

    public clickListener(e: any, it: any) {
        e.stopPropagation();
        if (this.multi) {
            this.toggleItem(it);
            return;
        }
        this.toggleIsShowList();
        this.currentItem = it;
        this.clickEvent.emit(it);
    }

    public toggleItem(it: any) {
        if (this.selectedList.find(item => item[this.fieldName] === it[this.fieldName])) {
            this.selectedList = this.selectedList.filter(item => item[this.fieldName] !== it[this.fieldName]);
            this.clickEvent.emit(it);
            return;
        }

        this.selectedList.push(it);
        this.clickEvent.emit(it);
    }

    public isItemChecked(b: any): boolean {
        return !!this.selectedList.find(item => item[this.fieldName] === b[this.fieldName]);
    }

    public genTitle(): string {
        if (this.changeableTitle) {
            if (this.currentItem) {
                return this.fieldName ? this.currentItem[this.fieldName] : this.currentItem;
            }
            return this.label;
        }
        return this.label;
    }
}
