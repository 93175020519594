import { Component, Input } from '@angular/core';
import { ApexAxisChartSeries, ApexGrid, ApexPlotOptions, ApexStroke } from 'ng-apexcharts';
import { ApexChartCustom } from 'src/app/_core/_models/_apex-charts/apex-chart-custom.model';
import { ChartComponent } from '../chart/chart.component';

@Component({
    selector: 'app-area-chart',
    templateUrl: './area-chart.component.html',
    styleUrls: ['./area-chart.component.scss']
})
export class AreaChartComponent extends ChartComponent {
    public barSeries: ApexAxisChartSeries = [{ name: '', data: [] }];

    @Input() lineLabel: string;

    override _series: number[];
    @Input()
    public override set series(s: number[]) {
        setTimeout(() => {
            this.barSeries = [{ data: s, name: this.lineLabel }];
            this.chartView.updateSeries([{ data: s, name: this.lineLabel }]);
        }, 0);
    }
    public override get series(): number[] {
        return this._series;
    }

    public apexPlotOptions: ApexPlotOptions = {
        bar: {
            borderRadius: 20
        }
    };

    public apexGrid: ApexGrid = {
        row: {
            opacity: 0.5
        }
    };

    public apexStroke: ApexStroke = { curve: 'smooth' };

    public apexXaxis = {
        categories: this.labels
    };

    public apexChart: ApexChartCustom = {
        width: '100%',
        height: 300,
        type: 'area',
        zoom: {
            enabled: true
        },
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        },
        toolbar: {
            tools: {
                download: true
            }
        },
        defaultLocale: 'ru',
        locales: [
            {
                name: 'ru',
                options: {
                    months: [
                        'Январь',
                        'Февраль',
                        'Март',
                        'Апрель',
                        'Май',
                        'Июнь',
                        'Июль',
                        'Август',
                        'Сентябрь',
                        'Октябрь',
                        'Ноябрь',
                        'Декабрь'
                    ],
                    shortMonths: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
                    days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
                    shortDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                    toolbar: {
                        exportToSVG: 'Сохранить SVG',
                        exportToPNG: 'Сохранить PNG',
                        exportToCSV: 'Сохранить CSV',
                        selection: 'Выбор',
                        selectionZoom: 'Выбор с увеличением',
                        zoomIn: 'Увеличить',
                        zoomOut: 'Уменьшить',
                        pan: 'Перемещение',
                        reset: 'Сбросить увеличение'
                    }
                }
            }
        ]
    };

    public apexDataLabels = {
        enabled: false
    };

    constructor() {
        super();
    }

    override ngOnInit(): void {}
}
