import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-mark',
  templateUrl: './card-mark.component.html',
  styleUrls: ['./card-mark.component.scss'],
})
export class CardMarkComponent implements OnInit {
  @Input() color: string;
  @Input() label: string;
  @Input() value: string;

  constructor() {}

  ngOnInit(): void {}
}
