import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
//models
import { TONE_ENUM } from 'src/app/_core/_components/sidebar-filter/sidebar-filter.component';
import { ENTRY_TYPE } from 'src/app/_helpers/_entryType';
import { GeneralFilter } from 'src/app/_core/_models/filter-models/general-filter';
import { IBookmark } from 'src/app/_core/_models/bookmark.model';
import { ICategoryCount } from 'src/app/_core/_models/category-count.model';
import { ProjectModel } from 'src/app/_core/_models/project-model';
import { ReportModel } from 'src/app/_core/_models/report.model';
import { ToneType } from 'src/app/_core/_models/analytics.model';
//services
import { AnalyticsService } from 'src/app/_core/_services/analytics.service';
import { FavouritesService } from 'src/app/_core/_services/favourites.service';
import { ProjectService } from 'src/app/_core/_services/project.service';
import { ReportsService } from 'src/app/_core/_services/reports.service';
//component
import { ReportDialogComponent } from './_components/report-dialog/report-dialog.component';
import { DownloadReportDialogComponent } from './_components/download-report-dialog/download-report-dialog.component';
import { TONES_LIST } from 'src/app/_helpers/_tones';

const titleDict: { [key: string]: string } = {
    topSource: 'Топ источников',
    topSocialNetwork: 'Топ Социальных сетей',
    topFacebook: 'Топ facebook',
    topInstagram: 'Топ instagram',
    topTelegram: 'Топ Telegram',
    topNegative: 'Топ негативных',
    topNeutral: 'Топ нейтральных',
    topPositive: 'Топ позитивных'
};

@Component({
    selector: 'app-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
    public filter: GeneralFilter = {};

    public contentType: Array<any> = [
        {
            name: 'Топ источников',
            value: 'topSource'
        },
        {
            name: 'Топ социальных сетей',
            value: 'topSocialNetwork'
        },
        {
            name: 'Топ Facebook',
            value: 'topFacebook'
        },
        {
            name: 'Топ Instagram',
            value: 'topInstagram'
        },
        {
            name: 'Топ Telegram',
            value: 'topTelegram'
        },
        {
            name: 'Топ негативных',
            value: 'topNegative'
        },
        {
            name: 'Топ нейтральных',
            value: 'topNeutral'
        },
        {
            name: 'Топ позитивных',
            value: 'topPositive'
        }
    ];

    public dateRange: Array<any> = [
        {
            name: 'Сегодня',
            value: 0
        },
        {
            name: 'Вчера',
            value: 1
        },
        {
            name: 'Неделя',
            value: 7
        },
        {
            name: 'Месяц',
            value: 30
        }
    ];
    public selectedDateRange: any;

    //loaders
    public isLoadingCategories: boolean;
    public isLoadingSource: boolean;
    public isLoadingtoneCoeficentList: boolean;
    public isLoadingTonesCount: boolean;
    public isLoadingEntriesByHour: boolean;
    public isLoadingEntriesByDays: boolean;

    //data for charts
    public categories: ICategoryCount[] = [];
    public categoryCountList: Array<number> = [];
    public categoryNameList: Array<string> = [];
    public toneCoeficentList: Array<any> = [];
    public toneCoeficentNameList: Array<any> = [];
    public topSource: Array<any> = [];
    public topSourceCountList: Array<any> = [];
    public topSourceNameList: Array<any> = [];
    public choosenSourceType: string;

    //dropdowns list
    public favourites: IBookmark[] = [];
    public projects: Array<ProjectModel> = [];
    public typeEntryList: Array<any> = ENTRY_TYPE;

    public contentItems: { source: string; count: number };
    public currentContentType: string;

    public contentTitles = titleDict;
    public contentValues: Array<any>;

    public currentContentTypeTitle: string;
    public entriesCountByDay: Array<number> = [];
    public entriesCountByHour: Array<number> = [];
    public entriesDay: Array<string> = [];
    public entriesHours: Array<string> = [];
    public entryType: string;
    public form: FormGroup;

    public negativeCount: number = 0;
    public neutralCount: number = 0;
    public positiveCount: number = 0;
    public projectDaysReport: ReportModel;
    public projectHoursReport: ReportModel;

    public toneHoursReport: ReportModel;
    public tonesByHours: Array<any> = [];
    public tonesCount: Array<number> = [];
    public tonesNameList: Array<string> = [];

    public selectedDateTemplate: Date;

    public today: Date = new Date();

    public rangeDate: number;

    public toneList = TONES_LIST;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _analyticsService: AnalyticsService,
        private _projectService: ProjectService,
        private _favouriteService: FavouritesService,
        private _reportsService: ReportsService,
        private _matDialog: MatDialog
    ) {
        this._createForm();
    }

    ngOnInit(): void {
        this.filter.startDate = new Date(this.today.getFullYear(), this.today.getMonth() - 7, this.today.getDate(), 0, 0, 0).toISOString();

        this._formListener();
        this._queryListener();

        //for dropdowns
        combineLatest({
            projects: this._projectService.getAll(),
            favourites: this._favouriteService.getFavourites()
        }).subscribe(response => {
            this.favourites = response.favourites;
            this.projects = response.projects;
        });

        //for charts
        this._fetchToneCoefficientByHour();
        this._fetchEntriesGroupedTodayByHours();
        this._fetchEntriesGrouppedByDay();
    }

    public _fetchTopSource(): void {
        this.isLoadingSource = true;
        this._analyticsService.getTopSource(this.filter).subscribe({
            next: response => {
                this.contentItems = response;
                this.topSourceCountList = [];
                this.topSourceNameList = [];
                this.topSource = response;
                this.topSource.forEach(source => {
                    this.topSourceCountList.push(source.count);
                    this.topSourceNameList.push(source.source);
                });
                this.isLoadingSource = false;
            }
        });
    }

    public fetchTopSocialNetworks(): void {
        this.isLoadingSource = true;
        this._analyticsService.getTopSocialNetworks(this.filter).subscribe({
            next: response => {
                this.contentItems = response;
                this.setSourcePie(response);
                this.isLoadingSource = false;
            }
        });
    }
    public fetchTopDetailSocialNetwork(socialNetwork: string): void {
        this.isLoadingSource = true;

        this._analyticsService
            .getTopSourceDetailSocialNetwork({
                ...this.filter,
                socialNetwork: socialNetwork
            })
            .subscribe({
                next: response => {
                    this.contentItems = response;
                    this.contentItems = response;
                    this.setSourcePie(response);
                    this.isLoadingSource = false;
                }
            });
    }
    public fetchTopSourceByTone(tone: ToneType): void {
        this.isLoadingSource = true;

        this._analyticsService.getTopSourceByTone({ tone }).subscribe({
            next: response => {
                this.contentItems = response;
                this.setSourcePie(response);
                this.isLoadingSource = false;
            }
        });
    }

    public sendReportToEmail(): void {
        this._reportsService.setFilter(this.filter);
        this._matDialog.open(ReportDialogComponent);
    }

    public handleContentTypeFilter(params: string): void {
        this.currentContentTypeTitle = this.contentTitles[params];
        this._router.navigate([], { queryParams: { contentType: params } });
    }

    public handleEventProjectListener(event: any): void {
        this.form.controls['project'].setValue(event);
    }
    public handleEventEntryTypeListener(event: any): void {
        this.form.controls['entryType'].setValue(event.value);
    }
    public handleEventBookmarkListener(event: any): void {
        this.form.controls['bookmark'].setValue(event);
    }

    public handleEventSourceListener(event: any): void {
        this.handleContentTypeFilter(event.value);
    }
    public handleEventToneListener(event: any): void {
        if (event) {
            this.form.controls['tone'].setValue(event.value);
        } else {
            this.form.controls['tone'].setValue(undefined);
        }
    }

    public handleFormReport(): void {
        this._queryListener();
    }

    private _queryListener(): void {
        this._route.queryParams.subscribe(params => {
            this.currentContentType = params['contentType'] || 'topSource';
            this.currentContentTypeTitle = this.contentTitles[params['contentType'] ? params['contentType'] : 'topSource'];
            if (this.currentContentType === 'topSource') {
                this._fetchCategories();
                this._fetchTopSource();
                this._fetchEntriesTone();
                this._fetchEntriesGrouppedByDay();
            }
            if (this.currentContentType === 'topSocialNetwork') {
                this.form.controls['socialNetworks'].setValue(true);
                this._fetchCategories();
                this.fetchTopSocialNetworks();
                this._fetchEntriesTone();
                this._fetchToneCoefficientByHour();
                this._fetchEntriesGrouppedByDay();
            }
            if (this.currentContentType === 'topFacebook') {
                this.form.controls['source'].setValue('facebook');
                this._fetchEntriesTone();
                this._fetchCategories();
                this.fetchTopDetailSocialNetwork('facebook');
                this._fetchToneCoefficientByHour();
                this._fetchEntriesGrouppedByDay();
            }
            if (this.currentContentType === 'topInstagram') {
                this.form.controls['source'].setValue('instagram');
                this._fetchEntriesTone();
                this.fetchTopDetailSocialNetwork('instagram');
                this._fetchCategories();
                this._fetchToneCoefficientByHour();
                this._fetchEntriesGrouppedByDay();
            }
            if (this.currentContentType === 'topTelegram') {
                this.form.controls['source'].setValue('telegram');
                this._fetchEntriesTone();
                this.fetchTopDetailSocialNetwork('telegram');
                this._fetchCategories();
                this._fetchToneCoefficientByHour();
                this._fetchEntriesGrouppedByDay();
            }
            if (this.currentContentType === 'topNegative') {
                this.form.controls['tone'].setValue('NEGATIVE');
                this._fetchEntriesTone();
                this._fetchCategories();
                this.fetchTopSourceByTone('NEGATIVE');
                this._fetchEntriesGrouppedByDay();
            }
            if (this.currentContentType === 'topNeutral') {
                this.form.controls['tone'].setValue('NEUTRAL');
                this._fetchEntriesTone();
                this._fetchCategories();
                this.fetchTopSourceByTone('NEUTRAL');
                this._fetchEntriesGrouppedByDay();
            }
            if (this.currentContentType === 'topPositive') {
                this.form.controls['tone'].setValue('POSITIVE');
                this._fetchEntriesTone();
                this._fetchCategories();
                this.fetchTopSourceByTone('POSITIVE');
                this._fetchEntriesGrouppedByDay();
            }
        });
    }

    private _formListener(): void {
        this.form.valueChanges.subscribe(value => {
            if (value.startDate) {
                this.filter.startDate = new Date(value.startDate).toISOString();
            }
            if (value.endDate) {
                this.filter.endDate = new Date(value.endDate).toISOString();
            }
            this.filter.entryType = value.entryType ? value.entryType : undefined;
            this.filter.projectId = value.project ? value.project.id : undefined;
            this.filter.bookmarkId = value.bookmark ? value.bookmark.id : undefined;
            this.filter.tone = value.tone ? value.tone : undefined;
            this.filter.source = value.source ? value.source : undefined;
            this.filter.socialNetworks = value.socialNetworks ? value.socialNetworks : undefined;

            this.isBigRangeDates();
        });
    }

    public clearFilter(): void {
        this.form.reset();
    }

    private _fetchCategories(): void {
        this.isLoadingCategories = true;
        this._analyticsService.getEntriesCategories(this.filter).subscribe({
            next: response => {
                this.categories = response;
                this.setCategoriesPie(response);
                this.isLoadingCategories = false;
            }
        });
    }

    private _fetchToneCoefficientByHour(): void {
        this.isLoadingtoneCoeficentList = true;
        this._analyticsService.getToneCoefficientByHour(this.filter).subscribe(response => {
            const toneCoeficient = response;
            this.toneCoeficentList = [];
            this.toneCoeficentNameList = [];
            toneCoeficient.forEach(tone => {
                this.toneCoeficentList.push((tone.count * 100).toFixed(2));
                this.toneCoeficentNameList.push(tone.name);
            });
            this.isLoadingtoneCoeficentList = false;
        });
    }

    private _fetchEntriesGroupedTodayByHours(): void {
        this.isLoadingEntriesByHour = true;
        this._analyticsService.getEntriesGroupedTodayByHours(this.filter).subscribe({
            next: response => {
                this.entriesCountByHour = [];
                this.entriesHours = [];
                response.forEach(entry => {
                    this.entriesCountByHour.push(entry.count);
                    this.entriesHours.push(entry.name);
                });
                this.isLoadingEntriesByHour = false;
            }
        });
    }

    private _fetchEntriesGrouppedByDay(): void {
        this.isLoadingEntriesByDays = true;
        this._analyticsService.getEntriesGroupedByDay(this.filter).subscribe({
            next: response => {
                this.entriesCountByDay = [];
                this.entriesDay = [];
                response
                    .slice()
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .forEach(entry => {
                        this.entriesCountByDay.push(entry.count);
                        this.entriesDay.push(entry.name);
                    });
                this.isLoadingEntriesByDays = false;
            }
        });
    }

    private _fetchEntriesTone(): void {
        this.isLoadingTonesCount = true;
        this._analyticsService.getEntriesTone(this.filter).subscribe({
            next: response => {
                this.tonesCount = [];
                this.tonesNameList = [];
                response.forEach((tone, i) => {
                    this.tonesCount.push(tone.count);
                    this.tonesNameList.push(TONE_ENUM[tone.name as keyof typeof TONE_ENUM]);

                    if (tone.name === 'POSITIVE') {
                        this.positiveCount = tone.count;
                    }
                    if (tone.name === 'NEUTRAL') {
                        this.neutralCount = tone.count;
                    }
                    if (tone.name === 'NEGATIVE') {
                        this.negativeCount = tone.count;
                    }
                });
                this.isLoadingTonesCount = false;
            }
        });
    }

    public setSourcePie(source: ReportModel[]): void {
        this.topSource = source;
        this.topSourceCountList = [];
        this.topSourceNameList = [];
        this.topSource.forEach(source => {
            this.topSourceCountList.push(source.count);
            this.topSourceNameList.push(source.source ? source.source : source.author);
        });
    }
    public setCategoriesPie(source: any): void {
        this.categories = source;
        this.categoryCountList = [];
        this.categoryNameList = [];
        this.categories.forEach(source => {
            this.categoryCountList.push(source.count);
            this.categoryNameList.push(source.name);
        });
    }

    public _changeFilter(data: any): void {
        this.form.get('startDate')?.setValue(data.startDate);
        this.form.get('endDate')?.setValue(data.endDate);
    }

    public selectDateRange(range: any) {
        this.selectedDateRange = range;
        this.selectedDateTemplate = new Date(this.today.getFullYear(), this.today.getMonth() - 7, this.today.getDate() - range.value, 0, 0, 0);

        this.form.controls['startDate'].setValue(this.selectedDateTemplate);
        this.filter = { ...this.filter, startDate: this.selectedDateTemplate.toISOString() };
    }

    public isBigRangeDates(): boolean {
        let startDate = this.form.controls['startDate'].value;
        let endDate = this.form.controls['endDate'].value;
        let range = 0;
        if (startDate) {
            range = Math.abs((new Date(endDate ? endDate : this.today).getTime() - new Date(startDate).getTime()) / (1000 * 24 * 60 * 60));
        }
        return range < 8;
    }

    private _createForm(): void {
        this.form = new FormGroup({
            bookmark: new FormControl(),
            endDate: new FormControl(),
            entryType: new FormControl(),
            project: new FormControl(),
            socialNetworks: new FormControl(),
            source: new FormControl(),
            startDate: new FormControl(),
            tone: new FormControl()
        });
    }

    public downloadReport(): void {
        this._reportsService.setFilter(this.filter);
        this._matDialog.open(DownloadReportDialogComponent);
    }
}
