<div class="monitoring">
    <div class="layout">
        <div class="header">
            <app-menu-item label="Добавить проекты" icon="assets/icons/plus.svg" (click)="openProjectDialog()"></app-menu-item>
            <app-menu-item label="Изменить проекты" icon="assets/icons/edit.svg" (click)="openProjectDialog()"></app-menu-item>
        </div>

        <div class="table">
            <table>
                <thead>
                    <tr>
                        <th>Название</th>
                        <th>Публикации</th>
                        <th>Непросмотренные публикации</th>
                        <th>Дата добавления</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="projects$ | async as projects; else tableSkeleton">
                        <ng-container *ngIf="projects.length">
                            <tr *ngFor="let project of projects">
                                <td class="project-name__td" (click)="navigateProjects(project.id)">{{ project.name }}</td>
                                <td class="data__td">{{ project.publicationCount }}</td>
                                <td class="data__td">{{ project.publicationUnreadCount }}</td>
                                <td class="data__td">
                                    {{ project.date.toString() | date : 'dd.MM' }}
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="!projects.length">
                            <tr>
                                <td colspan="4">Нет данных</td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table>
        </div>

        <ng-container *ngIf="tones$ | async as tones; else loader">
            <div class="marks">
                <app-card-mark
                    class="card-mark"
                    (click)="navigateTOnes('NEGATIVE')"
                    color="#E63946"
                    label="Негативные"
                    [value]="tones.negativeCount.toString()"
                ></app-card-mark>
                <app-card-mark
                    class="card-mark"
                    (click)="navigateTOnes('NEUTRAL')"
                    color="#a8dadccc"
                    label="Нейтральные"
                    [value]="tones.neutralCount.toString()"
                ></app-card-mark>
                <app-card-mark
                    class="card-mark"
                    (click)="navigateTOnes('POSITIVE')"
                    color="#0FD298"
                    label="Позитивные"
                    [value]="tones.positiveCount.toString()"
                ></app-card-mark>
            </div>
        </ng-container>

        <div class="pie-charts">
            <app-category-pie-chart
                class="pie-chart chart-categories"
                title="Категории"
                (sevent)="categoryListener($event)"
                [series]="categoryReport && categoryReport.series"
                [labels]="categoryReport && categoryReport.labels"
            ></app-category-pie-chart>

            <app-source-pie-chart
                class="pie-chart chart-categories"
                title="Источники"
                [series]="sourceReport && sourceReport.series"
                [labels]="sourceReport && sourceReport.labels"
            ></app-source-pie-chart>
        </div>

        <div class="charts">
            <div class="chart" *ngIf="projectDaysReport$ | async as projectDaysReport; else skeleton">
                <app-line-chart
                    title="Количество записей"
                    [series]="projectDaysReport.series"
                    [labels]="projectDaysReport.labels"
                    lineLabel="Количество записей"
                ></app-line-chart>
            </div>
            <div class="chart" *ngIf="toneHoursReport$ | async as toneHoursReport; else skeleton">
                <app-area-chart
                    title="Оценка тональности"
                    [colors]="['#0FD298']"
                    [series]="toneHoursReport.series"
                    [labels]="toneHoursReport.labels"
                    lineLabel="Коэффициент тональности"
                ></app-area-chart>
            </div>
            <div class="chart" *ngIf="projectHoursReport$ | async as projectHoursReport; else skeleton">
                <app-area-chart
                    title="Количество записей"
                    [colors]="['#bbcde5']"
                    [series]="projectHoursReport.series"
                    [labels]="projectHoursReport.labels"
                    lineLabel="Количество записей"
                ></app-area-chart>
            </div>
            <div class="chart" *ngIf="tonesReport$ | async as tonesReport; else skeleton">
                <app-bar-chart
                    title="Тональность"
                    [series]="tonesReport.series"
                    [labels]="tonesReport.labels"
                    barLabel="Количество записей"
                ></app-bar-chart>
            </div>
        </div>
    </div>
    <app-right-sidebar class="right-sidebar">
        <h3>Фильтр</h3>
        <app-sidebar-filter
            [filters]="['projectId', 'startDate', 'endDate']"
            (filterChangeEvent)="filterListener($event)"
        ></app-sidebar-filter>
    </app-right-sidebar>
</div>

<ng-template #loader>
    <div class="loader__wrapper">
        <app-loader></app-loader>
    </div>
</ng-template>

<ng-template #skeleton>
    <div class="skeleton__wrapper">
        <app-skeleton type="BAR_CHART"></app-skeleton>
    </div>
</ng-template>
<ng-template #tableLoader>
    <tr>
        <td colspan="4">
            <app-loader></app-loader>
        </td>
    </tr>
</ng-template>

<ng-template #tableSkeleton>
    <div class="skeleton-table__wrapper">
        <app-skeleton type="TABLE"></app-skeleton>
    </div>
</ng-template>
