import { Component, OnInit, Input, ViewChild, ElementRef, ContentChild, AfterContentInit, ɵViewRef, SecurityContext } from '@angular/core';
import { BookmarkModel } from '../../_models/bookmark.model';
import { EntryService } from '../../_services/entry.service';
import { FavouritesService } from '../../_services/favourites.service';
import { TONE_ENUM } from '../sidebar-filter/sidebar-filter.component';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { TagService } from '../../_services/tag.service';
import { TagModel } from '../../_models/tag-model';
import { SourcesService } from '../../_services/sources.service';
import { EntryModel } from '../../_models/entry.model';

@Component({
    selector: 'app-card-main',
    templateUrl: './card-main.component.html',
    styleUrls: ['./card-main.component.scss']
})
export class CardMainComponent implements OnInit, AfterContentInit {
    @Input()
    public entry: EntryModel;
    @Input() tags: string[];
    public bookmarks: Array<BookmarkModel>;
    public selectedBookmarks: Array<BookmarkModel>;
    public stableSelectedBookmarks: Array<BookmarkModel>;

    public text: string;
    public source: string;

    public favouriteLabels: Array<string> = [];

    constructor(
        private _entryService: EntryService,
        private _favouriteService: FavouritesService,
        private _toastr: ToastrService,
        private _sourceService: SourcesService
    ) {}
    ngAfterContentInit(): void {
        this.text = this.entry.text;
        if (this.tags?.length > 0) {
            this.tags.forEach(tag => (this.text = this.highlight(this.text, tag)));
        }

        if (this.entry.bookmarks.length > 0) {
            this.entry.bookmarks.forEach(item => {
                this._favouriteService.getById(item).subscribe(response => {
                    this.favouriteLabels.push(response.name);
                });
            });
        }
    }

    ngOnInit(): void {
        this._favouriteService._data$.subscribe(res => (this.bookmarks = res));

        this._sourceService.getSourceById(this.entry.sourceId).subscribe(response => {
            this.source = response.name;
        });
        console.log(this.entry.text);
    }

    public onOpenBookmark(): void {
        this._favouriteService.getAll().subscribe();
        this._favouriteService.getByEntryId(this.entry.entryId).subscribe((res: any) => {
            this.selectedBookmarks = res;
            this.stableSelectedBookmarks = JSON.parse(JSON.stringify(res));
        });
    }

    public changeTone(tone: TONE_ENUM): void {
        this.entry.tone = tone;

        let id = this.entry.entryId;

        this._entryService.updateTone(id, tone).subscribe({
            next: res => {
                this._toastr.success('Тональность успешно изменена');
            },
            error: err => {
                this._toastr.error('Ошибка при обновлении тональности');
            }
        });
    }

    public bookmarkCheckListener(bm: BookmarkModel): void {
        if (this.stableSelectedBookmarks.find(item => item.id === bm.id)) {
            this._favouriteService.deleteBookmarks(this.entry.entryId, bm).subscribe({
                next: res => this._toastr.success('Запись успешно отвязана от избранного'),
                error: err => this._toastr.error('Ошибка при обновлении записи')
            });
            this.stableSelectedBookmarks = this.stableSelectedBookmarks.filter(item => item.id !== bm.id);
            return;
        }
        this._favouriteService.setBookmarks(this.entry.entryId, bm).subscribe({
            next: res => this._toastr.success('Запись успешно привязана к избранному'),
            error: err => this._toastr.error('Ошибка при обновлении записи')
        });
        this.stableSelectedBookmarks.push(bm);
    }

    private highlight(text: string, tag: string): string {
        let index = text.toLowerCase().indexOf(tag.toLowerCase());
        if (index >= 0) {
            text =
                text.substring(0, index) +
                ` <span class="highlight"> ` +
                text.substring(index, index + tag.length) +
                ' </span>' +
                text.substring(index + tag.length);
        }
        return text;
    }
}
