import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { MonitoringToneModel } from './_models/monitoring-tone.model';
import { MonitoringCategoryService } from './_services/monitoring-category.service';
import { MonitoringProjectService } from './_services/monitoring-project.service';
import { MonitoringSourceService } from './_services/monitoring-source.service';
import { MonitoringToneService } from './_services/monitoring-tone.service';
import { MatDialog } from '@angular/material/dialog';
import { ProjectChangeComponent } from 'src/app/_core/_components/_project/project-change/project-change.component';
import { GeneralFilter } from 'src/app/_core/_models/filter-models/general-filter';
import { ENTRY_TYPE_ENUM, TONE_ENUM } from 'src/app/_core/_components/sidebar-filter/sidebar-filter.component';
import { ProjectDaysService } from './_services/project-days.service';
import { ProjectHoursService } from './_services/project-hours.service';
import { ToneHoursService } from './_services/tone-hours.service';
import { ReportModel } from 'src/app/_core/_models/report.model';
import { MonitoringProjectModel } from './_models/monitoring-project.model';
import { ToneType } from 'src/app/_core/_models/analytics.model';
import { Observable, of, map } from 'rxjs';
import { Router } from '@angular/router';
import { trans } from 'src/app/_helpers/locales';

@Component({
    selector: 'app-monitoring',
    templateUrl: './monitoring.component.html',
    styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent implements OnInit {
    @ViewChild('chart') chart: ChartComponent;

    public projects$: Observable<MonitoringProjectModel[]>;
    public projectDaysReport$: Observable<ReportModel>;
    public toneHoursReport$: Observable<ReportModel>;
    public projectHoursReport$: Observable<ReportModel>;
    public tones$: Observable<any>;
    public tonesReport$: Observable<MonitoringToneModel>;

    public categoryReport: ReportModel;
    public sourceReport: ReportModel;

    private _filter_cache: string;
    private _filter: GeneralFilter;
    public set filter(f: GeneralFilter) {
        this._filter = f;
        if (JSON.stringify(f) === this._filter_cache) return;
        this._filter_cache = JSON.stringify(f);
        f && this.updateAllData();
    }
    public get filter(): GeneralFilter {
        return this._filter;
    }

    constructor(
        private _monitoringProjectService: MonitoringProjectService,
        private _monitoringToneService: MonitoringToneService,
        private _projectDaysService: ProjectDaysService,
        private _toneHoursService: ToneHoursService,
        private _projectHoursService: ProjectHoursService,
        private _monitoringCategoryService: MonitoringCategoryService,
        private _monitoringSourceService: MonitoringSourceService,
        public dialog: MatDialog,
        public _router: Router
    ) {}

    ngOnInit(): void {
        this.initSubscribers();
    }

    public navigateProjects(projectId: number): void {
        this._router.navigate(['system/projects', projectId]);
    }

    public categoryListener(labelIndex: number): void {
        this._router.navigate(['/system/search', labelIndex.toString()]);
    }

    public sourceListener(labelIndex: number): void {
        this.filter.source = this.sourceReport.labels[labelIndex];
        this.filter = this.filter;
    }

    public typeClickListener(entryType: ENTRY_TYPE_ENUM) {
        this.filter.entryType = entryType;
    }

    public openProjectDialog(): void {
        let dialogRef = this.dialog.open(ProjectChangeComponent, {
            width: '450px'
        });

        dialogRef.afterClosed().subscribe(result => {});
    }

    public filterListener(data: GeneralFilter): void {
        if (this.filter) {
            data.category = this.filter.category;
            data.source = this.filter.source;
            data.entryType = this.filter.entryType;
        }
        this.filter = data;
    }

    public navigateTOnes(tone: TONE_ENUM): void {
        this._router.navigate(['system/tones', tone]);
    }

    public updateAllData(): void {
        this._monitoringProjectService.get(this.filter).subscribe();
        this._monitoringToneService.get(this.filter).subscribe();
        this._projectDaysService.get(this.filter).subscribe();
        this._toneHoursService.get(this.filter).subscribe();
        this._projectHoursService.get(this.filter).subscribe();
        this._monitoringCategoryService.get(this.filter).subscribe();
        this._monitoringSourceService.get(this.filter).subscribe();
    }

    public initSubscribers() {
        this.projects$ = this._monitoringProjectService._data$;

        this.tones$ = this._monitoringToneService.__dataObj$.pipe(
            map(res => {
                if (!res) return res;
                let formatted = {
                    negativeCount: this.formatNumberToStringWithSpaces(res.negativeCount),
                    neutralCount: this.formatNumberToStringWithSpaces(res.neutralCount),
                    positiveCount: this.formatNumberToStringWithSpaces(res.positiveCount)
                };
                return formatted;
            })
        );

        this.tonesReport$ = this._monitoringToneService._data$.pipe(
            map((res: any) => {
                console.log(res);
                if (res) {
                    res.labels = res.labels.map((item: any) => (trans as any)[item.toLowerCase()]);
                    return res;
                }
                return res;
            })
        );

        this.projectDaysReport$ = this._projectDaysService._data$;

        this.toneHoursReport$ = this._toneHoursService._data$.pipe(
            map(data => {
                if (data) {
                    data.series = data?.series.map((item: number) => item * 100);
                }
                return data;
            })
        );

        this.projectHoursReport$ = this._projectHoursService._data$;

        this._monitoringCategoryService._data$.subscribe(res => {
            this.categoryReport = res;
        });

        this._monitoringSourceService._data$.subscribe(res => {
            this.sourceReport = res;
        });
    }

    private formatNumberToStringWithSpaces(n: number): string {
        return String(n).replace(/(?!^)(?=(?:\d{3})+$)/g, ' ');
    }
}
