import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { GeneralFilter } from 'src/app/_core/_models/filter-models/general-filter';
import { ReportsService } from 'src/app/_core/_services/reports.service';
import { FORMAT_FILES } from 'src/app/_helpers/_formatFiles';

@Component({
    selector: 'app-download-report-dialog',
    templateUrl: './download-report-dialog.component.html',
    styleUrls: ['./download-report-dialog.component.scss']
})
export class DownloadReportDialogComponent implements OnInit {
    public isLoading: boolean;
    public form: FormGroup;
    public filter: GeneralFilter = {};
    public typeFiles = FORMAT_FILES;
    public chosenTypeFile: any;

    constructor(private _matDialog: MatDialog, private _reportsService: ReportsService, private _toastrService: ToastrService) {
        this._createForm();
    }

    ngOnInit(): void {
        this.form.valueChanges.subscribe(values => {
            this.filter = this._reportsService.getFilter();
            this._reportsService.setFilter(this.filter);
        });
    }
    public clickListener(item: any) {
        if (item.value) {
            this.chosenTypeFile = item.value;
            this.form.controls['fileType'].setValue(item.value.type);
            this.filter.type = item.value.type;
        } else {
            this.form.controls['fileType'].setValue(item.value);
        }
    }

    public sendReport(): void {
        this.isLoading = true;
        this._reportsService.downloadReport(this.filter).subscribe({
            next: respponse => {
                if (this.chosenTypeFile.type === 'XLSX') {
                    var blob = new Blob([respponse], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const link = document.createElement('a');
                    link.download = `Отчёт_${
                        this.filter.startDate ? new Date(this.filter.startDate).toLocaleString('ru').split(' ')[0].slice(0, -1) : '_'
                    }/${this.filter.endDate ? new Date(this.filter.endDate).toLocaleString('ru').split(' ')[0].slice(0, -1) : '_'}.xlsx`;
                    link.href = window.URL.createObjectURL(blob);
                    link.click();
                    link.remove();
                    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
                }
                if (this.chosenTypeFile.type === 'DOCX') {
                    var blob = new Blob([respponse], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                    const link = document.createElement('a');
                    link.download = `Отчёт_${
                        this.filter.startDate ? new Date(this.filter.startDate).toLocaleString('ru').split(' ')[0].slice(0, -1) : '_'
                    }/${this.filter.endDate ? new Date(this.filter.endDate).toLocaleString('ru').split(' ')[0].slice(0, -1) : '_'}.docx`;
                    link.href = window.URL.createObjectURL(blob);
                    link.click();
                    link.remove();
                    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
                }
                if (this.chosenTypeFile.type === 'PPTX') {
                    var blob = new Blob([respponse], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const link = document.createElement('a');
                    link.download = `Отчёт_${
                        this.filter.startDate ? new Date(this.filter.startDate).toLocaleString('ru').split(' ')[0].slice(0, -1) : '_'
                    }/${this.filter.endDate ? new Date(this.filter.endDate).toLocaleString('ru').split(' ')[0].slice(0, -1) : '_'}.pptx`;
                    link.href = window.URL.createObjectURL(blob);
                    link.click();
                    link.remove();
                    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
                }

                this.isLoading = false;
            }
        });
    }

    public closeDialog(): void {
        this._matDialog.closeAll();
    }

    public _createForm(): void {
        this.form = new FormGroup({
            fileType: new FormControl('', [Validators.required])
        });
    }
}
