import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GeneralFilter } from '../_models/filter-models/general-filter';

@Injectable({
    providedIn: 'root'
})
export class ReportsService {
    public filter: GeneralFilter = {};
    constructor(private _http: HttpClient) {}

    public getReports(input: GeneralFilter): Observable<any> {
        let queryParams = new HttpParams();
        Object.keys(input).map(k => {
            if ((input as any)[k]) {
                queryParams = queryParams.append(k, (input as any)[k]);
            }
        });
        return this._http.get(`${environment.apiUrl}/reports/files`, {
            params: queryParams, //@ts-ignore
            responseType: 'text'
        });
    }

    public downloadReport(input: GeneralFilter): Observable<any> {
        let queryParams = new HttpParams();
        Object.keys(input).map(k => {
            if ((input as any)[k]) {
                queryParams = queryParams.append(k, (input as any)[k]);
            }
        });
        return this._http.get(`${environment.apiUrl}/reports/download`, {
            params: queryParams, //@ts-ignore
            responseType: 'blob'
        });
    }

    public getFilter(): GeneralFilter {
        return this.filter;
    }

    public setFilter(filter: GeneralFilter): void {
        this.filter = filter;
    }
}
