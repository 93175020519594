<div class="notification">
    <div class="table">
        <div class="header">
            <div class="email">Email</div>
            <div class="projects-bookmarks">Проекты/Избранные</div>
            <div class="action">Действия</div>
        </div>
        <div class="body">
            <ng-container *ngIf="!isLoadingContent">
                <div class="body__item" *ngFor="let notification of notifications">
                    <div class="email">{{ notification.email }}</div>
                    <div class="project">{{ notification.project?.name ? notification.project?.name : 'Проект не выбран' }}</div>
                    <div class="action">
                        <button class="btn btn-delete" [disabled]="isLoading" (click)="handleDeleteNotification(notification.id)">
                            Удалить
                        </button>
                    </div>
                </div>

                <form class="form" [formGroup]="form" (submit)="handleSubmitForm()">
                    <div class="add-form">
                        <div class="email"><input type="text" formControlName="email" /></div>
                        <div class="projects-bookmarks">
                            <app-dropdown
                                label="Выбрать проект"
                                isSecondary="true"
                                [list]="projects"
                                changeableTitle="true"
                                (clickEvent)="onItemClick($event)"
                            ></app-dropdown>
                        </div>
                    </div>
                    <div class="action">
                        <button class="btn btn-primary" [disabled]="form.invalid || isLoading">Добавить</button>
                    </div>
                </form>
            </ng-container>
            <app-loader *ngIf="isLoadingContent"></app-loader>
        </div>
    </div>
</div>
