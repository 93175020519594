<div class="projects">
    <div class="layout">
        <div class="header">
            <div class="menu-list">
                <app-menu-item label="Изменить проект" icon="assets/icons/edit.svg" (click)="openProjectDialog()"></app-menu-item>
                <ng-container *ngIf="filter && projectId">
                    <app-menu-item label="Изменить тэги" icon="assets/icons/edit.svg" (click)="openTagDialog()"></app-menu-item>
                </ng-container>

                <ng-container *ngIf="!filter || !filter.projectId">
                    <app-menu-item
                        class="menu-item_disabled"
                        label="Изменить тэги"
                        icon="assets/icons/edit.svg"
                        title="Необходимо выбрать проект"
                    ></app-menu-item>
                </ng-container>
            </div>
        </div>

        <div class="chart" *ngIf="projectDaysReport$ | async as projectDaysReport; else skeleton">
            <app-line-chart
                title="Количество записей"
                [series]="projectDaysReport.series"
                [labels]="projectDaysReport.labels"
                lineLabel="Количество записей"
            ></app-line-chart>
        </div>
        <div class="marks">
            <ng-container *ngIf="tones$ | async as tones; else loader">
                <app-card-mark
                    class="card-mark"
                    (click)="navigateTOnes('NEGATIVE')"
                    color="#E63946"
                    label="Негативные"
                    [value]="tones.negativeCount.toString()"
                ></app-card-mark>
                <app-card-mark
                    class="card-mark"
                    (click)="navigateTOnes('NEUTRAL')"
                    color="#a8dadccc"
                    label="Нейтральные"
                    [value]="tones.neutralCount.toString()"
                ></app-card-mark>
                <app-card-mark
                    class="card-mark"
                    (click)="navigateTOnes('POSITIVE')"
                    color="#0FD298"
                    label="Позитивные"
                    [value]="tones.positiveCount.toString()"
                ></app-card-mark>
            </ng-container>
        </div>
        <ng-container *ngIf="locationTags.length > 0">
            <app-entry-list [tags]="locationTags" [filter]="filter"></app-entry-list>
        </ng-container>
    </div>
    <app-right-sidebar class="right-sidebar">
        <h3>Фильтр</h3>
        <app-sidebar-filter
            [projectId]="projectId"
            [filters]="['projectId', 'startDate', 'endDate', 'sourceType', 'tone', 'entryType', 'source']"
            (filterChangeEvent)="filterListener($event)"
        ></app-sidebar-filter>
    </app-right-sidebar>
</div>

<ng-template #loader>
    <div class="loader__wrapper">
        <app-loader></app-loader>
    </div>
</ng-template>

<ng-template #skeleton>
    <div class="skeleton__wrapper">
        <app-skeleton type="BAR_CHART"></app-skeleton>
    </div>
</ng-template>
