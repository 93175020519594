import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { ICategoryCount } from 'src/app/_core/_models/category-count.model';
import { GeneralFilter } from 'src/app/_core/_models/filter-models/general-filter';
import { ProjectModel } from 'src/app/_core/_models/project-model';
import { AnalyticsService } from 'src/app/_core/_services/analytics.service';
import { ProjectService } from 'src/app/_core/_services/project.service';
import { ReportsService } from 'src/app/_core/_services/reports.service';
import { FORMAT_DATA } from 'src/app/_helpers/_dataFormat';
import { ENTRY_TYPE } from 'src/app/_helpers/_entryType';
import { TONES_LIST, ITone } from 'src/app/_helpers/_tones';
@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
    public filter: GeneralFilter = {};

    public form: FormGroup;

    public formatData = FORMAT_DATA;
    public entryTypeList = ENTRY_TYPE;
    public neutralCount: number;
    public positiveCount: number;
    public negativeCount: number;

    public startDate: string;
    public endDate: string;

    public toneList: Array<ITone> = TONES_LIST;
    public projects: Array<ProjectModel>;

    public categories: Array<ICategoryCount>;
    public tonesCount: Array<number> = [];
    public tonesNameList: Array<string> = [];

    constructor(
        private _analyticsService: AnalyticsService,
        private _projectService: ProjectService,
        private _reportsService: ReportsService
    ) {
        this._createForm();
    }

    ngOnInit(): void {
        combineLatest({
            categories: this._analyticsService.getEntriesCategories(this.filter),
            projects: this._projectService.getAll()
        }).subscribe({
            next: response => {
                this.categories = response.categories;
                this.projects = response.projects;
            }
        });

        this._analyticsService.getEntriesTone({}).subscribe({
            next: response => {
                this.tonesCount = [];
                this.tonesNameList = [];
                response.forEach(tone => {
                    this.tonesCount.push(tone.count);
                    this.tonesNameList.push(tone.name);
                    if (tone.name === 'POSITIVE') {
                        this.positiveCount = tone.count;
                    }
                    if (tone.name === 'NEUTRAL') {
                        this.neutralCount = tone.count;
                    }
                    if (tone.name === 'NEGATIVE') {
                        this.negativeCount = tone.count;
                    }
                });
            }
        });

        this.form.valueChanges.subscribe(value => {
            this.filter.startDate = value.startDate ? value.startDate.toISOString() : undefined;
            this.filter.endDate = value.endDate ? value.endDate.toISOString() : undefined;
            this.filter.tone = value.tone ? value.tone.value : undefined;
            this.filter.category = value.category ? value.category.name : undefined;
            this.filter.projectId = value.projectId ? value.projectId : undefined;
            this.filter.type = value.type ? value.type : undefined;
            // this.filter.lang = value.lang ? value.lang : undefined;
            this.filter.withData = value.withData ? value.withData.value : undefined;
            this.filter.entryType = value.entryType.value ? value.entryType.value : undefined;
            console.log(this.filter);
        });
    }

    public handleEventToneListener(event: any): void {
        this.form.controls['tone'].setValue(event);
    }
    public handleEventCategoryListener(event: any): void {
        this.form.controls['category'].setValue(event);
    }
    public handleEventProjectListener(event: any): void {
        this.form.controls['projectId'].setValue(event.id);
    }

    public handleEventFormatFileListener(event: any): void {
        this.form.controls['type'].setValue(event.type);
    }

    public handleChooseLang(lang: string): void {
        this.form.controls['lang'].setValue(lang);
        this.handleFormatReport();
    }
    public handleEventFormatDataListener(data: string): void {
        this.form.controls['withData'].setValue(data);
    }
    public handleEventEntryTypeListener(data: any): void {
        this.form.controls['entryType'].setValue(data);
    }

    public handleFormatReport(): void {
        console.log(this.filter);
        this._reportsService.getReports(this.filter).subscribe({
            next: respponse => {
                var blob = new Blob([respponse], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.download =
                    'Отчёт ' +
                    new Date(this.form.controls['startDate'].value).toLocaleString('ru').split(' ')[0].slice(0, -1) +
                    '/' +
                    new Date(this.form.controls['endDate'].value).toLocaleString('ru').split(' ')[0].slice(0, -1) +
                    '.xlsx';
                link.href = window.URL.createObjectURL(blob);
                link.click();
                link.remove();
                setTimeout(() => URL.revokeObjectURL(link.href), 7000);
            }
        });
    }

    private _createForm(): void {
        this.form = new FormGroup({
            startDate: new FormControl(),
            endDate: new FormControl(),
            tone: new FormControl(),
            category: new FormControl(),
            type: new FormControl(),
            projectId: new FormControl(),
            lang: new FormControl(),
            withData: new FormControl(),
            entryType: new FormControl()
        });
    }
}
