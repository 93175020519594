<div class="dropdown">
    <div (click)="toggleIsShowList()">
        <ng-content></ng-content>
    </div>

    <ng-container *ngIf="label">
        <div class="header" (click)="toggleIsShowList()">
            <div class="chosen_label">{{ !currentItem ? '' : label }}</div>
            <div class="label">{{ genTitle() }}</div>
            <ng-container *ngIf="theme === 'default'">
                <img class="logo" src="assets/icons/arrow-down.svg" alt />
            </ng-container>
            <ng-container *ngIf="theme === 'white'">
                <img class="logo" src="assets/icons/arrow-down_white.svg" alt />
            </ng-container>
        </div>
    </ng-container>

    <div class="list" [class.list_open]="isShowList">
        <div class="list__item" (click)="clickListener($event, null)">
            <span class="option_clear">Сбросить</span>
        </div>
        <ng-container *ngFor="let item of list">
            <div class="list__item" (click)="clickListener($event, item)">
                <ng-container *ngIf="multi">
                    <img class="checked__icon" *ngIf="isItemChecked(item)" src="/assets/icons/checked.svg" alt />
                    <img class="checked__icon not-checked" *ngIf="!isItemChecked(item)" src="/assets/icons/not-checked.svg" alt />
                </ng-container>
                {{ fieldName ? item[fieldName] : item }}
            </div>
        </ng-container>
    </div>
</div>
