import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor() {}

  public getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  public getRefreshToken() {
    localStorage.removeItem('accessToken');
    return localStorage.getItem('refreshToken');
  }

  public saveAccessToken(token: string) {
    localStorage.removeItem('accessToken');
    localStorage.setItem('accessToken', token);
  }

  public saveRefreshToken(token: string) {
    localStorage.removeItem('refreshToken');
    localStorage.setItem('refreshToken', token);
  }

  public clearTokens(): void {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }
}
