<ng-container *ngIf="type.toString() === 'BAR_CHART'">
    <div class="loading_bar">
        <div class="loading-1 loader__bar"></div>
        <div class="loading-2 loader__bar"></div>
        <div class="loading-3 loader__bar"></div>
        <div class="loading-4 loader__bar"></div>
    </div>
</ng-container>


<ng-container *ngIf="type.toString() === 'PIE_CHART'">
    <div class="pie-chart__skeleton">
        <img
            src="/assets/icons/pie-chart.svg"
            alt
        >
    </div>
</ng-container>

<ng-container *ngIf="type.toString() === 'TABLE'">
  <table class="table">
    <tbody >
        <tr>
            <td class="loading">
                <div class="bar"></div>
            </td>
            <td class="loading">
                <div class="bar"></div>
            </td>
            <td class="loading">
                <div class="bar"></div>
            </td>
            <td class="loading">
              <div class="bar"></div>
          </td>
        </tr>
        <tr>
          <td class="loading">
              <div class="bar"></div>
          </td>
          <td class="loading">
              <div class="bar"></div>
          </td>
          <td class="loading">
              <div class="bar"></div>
          </td>
          <td class="loading">
            <div class="bar"></div>
        </td>
      </tr>
      <tr>
        <td class="loading">
            <div class="bar"></div>
        </td>
        <td class="loading">
            <div class="bar"></div>
        </td>
        <td class="loading">
            <div class="bar"></div>
        </td>
        <td class="loading">
          <div class="bar"></div>
      </td>
    </tr>
    </tbody>
  </table>
</ng-container>
