import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './_core/core.module';
import { AuthGuard } from './_core/_guards/auth.guard';
import { AuthService } from './_core/_services/auth.service';
import { AuthModule } from './_modules/auth/auth.module';
import { SystemModule } from './_modules/system/system.module';
import { Interceptor } from './_core/_interceptors/token.interceptor';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        CoreModule,
        AuthModule,
        SystemModule,
        BrowserAnimationsModule, // required animations module
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-center'
        })
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true }],
    bootstrap: [AppComponent]
})
export class AppModule {}
