<div class="monitoring">
  <div class="layout">
      <div class="charts">
          <div class="pie-charts">
              <ng-container *ngIf="categoryReport">
                  <app-pie-chart
                      class="pie-chart chart-categories"
                      title="Категории"
                      [series]="categoryReport.series"
                      [labels]="categoryReport.labels"
                      (dataClickEvent)="categoryListener($event)"
                  ></app-pie-chart>
              </ng-container>
              <ng-container *ngIf="!categoryReport">
                <app-skeleton type="PIE_CHART"></app-skeleton>
              </ng-container>


              <ng-container *ngIf="sourceReport">
                  <app-pie-chart
                      class="pie-chart chart-categories"
                      title="Источники"
                      [series]="sourceReport.series"
                      [labels]="sourceReport.labels"
                      (dataClickEvent)="sourceListener($event)"
                  ></app-pie-chart>
              </ng-container>
              <ng-container *ngIf="!sourceReport">
                <app-skeleton type="PIE_CHART"></app-skeleton>
              </ng-container>

          </div>
      </div>

      <app-entry-list [filter]="filter"></app-entry-list>
  </div>
  <app-right-sidebar class="right-sidebar">
    <h3>Фильтр</h3>
      <app-sidebar-filter
          [filters]="[ 'startDate', 'endDate', 'sourceType']"
          (filterChangeEvent)="filterListener($event)"
      ></app-sidebar-filter>
  </app-right-sidebar>
</div>

<ng-template #skeleton>
  <div class="skeleton__wrapper">
  <app-skeleton type="BAR_CHART"></app-skeleton>
</div>
</ng-template>
