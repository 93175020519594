<img (click)="closeDialog()" class="close-icon" src="/assets/icons/plus.svg" alt="" />

<ng-container *ngIf="!isDataLoading">
    <ng-container *ngIf="formArray && formArray.controls.length > 0">
        <div class="items">
            <ng-container *ngFor="let fg of formArray.controls; let i = index">
                <div class="project" [formGroup]="fg">
                    <div class="project__name">
                        <input type="text" formControlName="name" />
                    </div>
                    <div class="project__action" *ngIf="fg.disabled">
                        <button class="btn-icon edit change" (click)="fg.enable()">
                            <div class="icon__wrapper" title="Редактировать">
                                <img src="/assets/icons/edit-icon.svg" alt="" />
                            </div>
                        </button>
                        <button class="btn-icon delete" (click)="delete(fg, i)">
                            <div class="icon__wrapper" title="Удалить">
                                <img src="/assets/icons/delete-icon.svg" alt="" />
                            </div>
                        </button>
                    </div>
                    <div class="project__action" *ngIf="!fg.disabled">
                        <button class="btn-icon save" (click)="change(fg)">
                            <div class="icon__wrapper" title="Сохранить">
                                <img src="/assets/icons/save-icon.svg" alt="" />
                            </div>
                        </button>
                        <button class="btn-icon cancel" (click)="fg.disable()">
                            <div class="icon__wrapper" title="Отменить">
                                <img src="/assets/icons/cancel-icon.svg" alt="" />
                            </div>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <div class="project" [formGroup]="newProjectFormGroup">
        <div class="project__name">
            <input type="text" formControlName="name" placeholder="Введите название" />
        </div>
        <div class="project__action">
            <button (click)="add()" class="btn btn-primary btn-add" [disabled]="isAddLoading">
                <ng-container *ngIf="!isAddLoading">Добавить</ng-container>
                <img class="loader" *ngIf="isAddLoading" src="./assets/icons/loader.svg" alt="Loader" />
            </button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isDataLoading">
    <app-loader></app-loader>
</ng-container>
