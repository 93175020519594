<div class="pie-chart">
    <div
        class="title"
        [ngStyle]="{
            color: isSecondary ? '#09121f' : ''
        }"
    >
        {{ title }}
    </div>
    <apx-chart
        [series]="series"
        [chart]="apexChart"
        [labels]="labels"
        [legend]="apexLegend"
        [stroke]="apexStroke"
        [dataLabels]="apexDataLabels"
        #chart
    ></apx-chart>
</div>
