<div class="analytics">
    <div class="layout">
        <div class="content-table">
            <div class="header">
                <h2 class="title">{{ currentContentTypeTitle }}</h2>
            </div>
            <div class="content">
                <div class="content__header">
                    <div class="content__header__item">Название</div>
                    <div class="content__header__item">Количество</div>
                </div>
                <ng-container *ngIf="!isLoadingSource">
                    <div class="content__item" *ngFor="let item of contentItems">
                        <div class="label">{{ item.source ? item.source : item.author }}</div>
                        <div class="value">{{ item.count }}</div>
                    </div>
                </ng-container>
                <app-loader *ngIf="isLoadingSource"></app-loader>
            </div>
        </div>
        <div class="marks" [ngStyle]="{ 'justify-content': isLoadingTonesCount ? 'center' : '' }">
            <ng-container *ngIf="!isLoadingTonesCount">
                <app-card-mark label="Негативные отзывы" color="#E63946" [value]="negativeCount"></app-card-mark>
                <app-card-mark label="Нейтральные записи" color="#A8DADC" [value]="neutralCount"></app-card-mark>
                <app-card-mark label="Позитивные записи" color="#0FD298" [value]="positiveCount"></app-card-mark>
            </ng-container>
            <app-loader *ngIf="isLoadingTonesCount"> </app-loader>
        </div>

        <div class="pie-charts">
            <ng-container *ngIf="!isLoadingCategories">
                <app-pie-chart
                    *ngIf="categories.length > 0"
                    class="pie-chart chart-categories"
                    title="Категории"
                    [labels]="categoryNameList"
                    [series]="categoryCountList"
                    isSecondary="true"
                ></app-pie-chart>
            </ng-container>
            <app-loader *ngIf="isLoadingCategories"></app-loader>
            <ng-container *ngIf="!isLoadingSource">
                <app-pie-chart
                    *ngIf="topSource.length > 0"
                    class="pie-chart chart-categories"
                    title="Источники"
                    [labels]="topSourceNameList"
                    [series]="topSourceCountList"
                    isSecondary="true"
                ></app-pie-chart>
            </ng-container>
            <ng-container *ngIf="isLoadingSource">
                <app-loader></app-loader>
            </ng-container>
        </div>

        <div class="line-charts">
            <app-area-chart
                *ngIf="!isLoadingtoneCoeficentList"
                title="Оценка тональности"
                [labels]="toneCoeficentNameList"
                [series]="toneCoeficentList"
                lineLabel="Коэффициент тональности"
            ></app-area-chart>
            <app-loader *ngIf="isLoadingtoneCoeficentList"></app-loader>
            <app-area-chart
                *ngIf="!isLoadingEntriesByHour"
                title="Количество записей по часам"
                [labels]="entriesHours"
                [series]="entriesCountByHour"
                lineLabel="Количество записей"
            ></app-area-chart>

            <app-loader *ngIf="isLoadingEntriesByHour"></app-loader>

            <app-bar-chart
                *ngIf="!isLoadingTonesCount"
                title="Тональность"
                [series]="tonesCount"
                [labels]="tonesNameList"
                barLabel="Количество записей"
            >
            </app-bar-chart>
            <app-line-chart
                *ngIf="!isLoadingEntriesByDays"
                title="Количество записей по дням"
                [labels]="entriesDay"
                [series]="entriesCountByDay"
                lineLabel="Количество записей"
            ></app-line-chart>
            <app-loader *ngIf="isLoadingEntriesByDays"></app-loader>
        </div>
    </div>
    <app-right-sidebar>
        <div class="right-sidebar__content">
            <app-sidebar-filter
                [isStartDateToday]="true"
                [selectedStartDate]="selectedDateTemplate"
                [filters]="['startDate', 'endDate']"
                (filterChangeEvent)="_changeFilter($event)"
            ></app-sidebar-filter>

            <div class="filters">
                <app-dropdown
                    label="Выберите тональность"
                    [list]="toneList"
                    [value]="form.controls['tone']"
                    (clickEvent)="handleEventToneListener($event)"
                ></app-dropdown>
                <app-dropdown
                    label="Выберите период"
                    [list]="dateRange"
                    [value]="selectedDateRange"
                    (clickEvent)="selectDateRange($event)"
                ></app-dropdown>
                <app-dropdown
                    label="Выберите источник"
                    [list]="contentType"
                    (clickEvent)="handleEventSourceListener($event)"
                ></app-dropdown>
                <app-dropdown
                    *ngIf="favourites.length > 0"
                    label="Выберите избранное"
                    [list]="favourites"
                    [value]="form.controls['bookmark'].value"
                    (clickEvent)="handleEventBookmarkListener($event)"
                ></app-dropdown>

                <app-dropdown
                    *ngIf="projects.length > 0"
                    label="Выберите проект"
                    [value]="form.controls['project'].value"
                    [list]="projects"
                    (clickEvent)="handleEventProjectListener($event)"
                ></app-dropdown>
                <app-dropdown
                    label="Выберите тип записи"
                    [list]="typeEntryList"
                    [value]="form.controls['entryType'].value"
                    (clickEvent)="handleEventEntryTypeListener($event)"
                ></app-dropdown>
            </div>
            <div class="btns">
                <button class="btn btn-secondary btn-form" (click)="handleFormReport()">Сформировать</button>
                <button class="btn btn-secondary" (click)="clearFilter()">Сбросить фильтр</button>
                <button class="btn btn-secondary" (click)="sendReportToEmail()">Отправить отчет на почту</button>
                <button
                    id="download-report"
                    class="btn btn-secondary"
                    *ngIf="isBigRangeDates()"
                    (click)="downloadReport()"
                    title="Отчет можно скачать, если период не превышает 7 дней"
                >
                    Скачать отчет
                </button>
            </div>
        </div>
    </app-right-sidebar>
</div>
