import { Component, ContentChild, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EntryModel } from 'src/app/_core/_models/entry.model';
import { GeneralFilter } from 'src/app/_core/_models/filter-models/general-filter';
import { SearchService } from 'src/app/_core/_services/search.service';
import { map } from 'rxjs';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
    public entries: EntryModel[] = [];
    public searchText: string;
    public searchTextTags: Array<string>;
    public filter: GeneralFilter = {};

    public isLoading: boolean = false;

    //pagination
    public pageSize: number;
    public page: number;
    public totalCount: number;

    constructor(private _route: ActivatedRoute, private searchService: SearchService) {}

    ngOnInit(): void {
        this.isLoading = true;
        this._route.queryParams.subscribe(params => {
            if (params['q'] !== this.searchText) {
                this.page = 1;
                this.filter = { ...this.filter, page: this.page };
            }
            this.searchText = params['q'] || '';
            if (this.searchText) {
                this.searchTextTags = this.searchText.split(' ');
                this.filter.q = this.searchText;
                this._fetchSearch();
            }
            if (!this.searchText) {
                this.entries = [];
                this.isLoading = false;
            }
        });
    }

    private _fetchSearch(): void {
        this.isLoading = true;
        this.searchService.search(this.filter).subscribe(response => {
            this.entries = response.items;
            this.totalCount = Math.ceil(response.totalCount / 20);
            this.page = response.pageNumber;
            this.isLoading = false;
        });
    }

    public onPageClick(page: number): void {
        this.page = page;
        this.filter = { ...this.filter, page };
        this._fetchSearch();
    }

    public filterListener(data: GeneralFilter): void {
        this.filter = { ...this.filter, ...data };
        if (this.filter.q) {
            this._fetchSearch();
        }
    }
}
