import { HttpClient, HttpParams } from '@angular/common/http';
import { inject } from '@angular/core';
import { Subject, Observable, of, BehaviorSubject, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GeneralFilter } from '../_models/filter-models/general-filter';
import { ReportDto, ReportModel } from '../_models/report.model';

export abstract class MonitoringService {
    protected readonly _data;
    public _data$;

    public _http: HttpClient = inject(HttpClient);

    private _filterCache: string;
    protected dataCache: any;

    public isLoading = false;

    public isLoadingSubject = new BehaviorSubject(false);
    public isLoading$: Observable<boolean>;
    constructor() {
        this._data = new Subject<any>();
        this._data$ = this._data.asObservable();
        this.isLoading$ = this.isLoadingSubject.asObservable();
    }

    protected changeData(data: any, isNotReportData?: boolean): void {
        if (data === null) this._data.next(null);
        this.dataCache = data;

        if (isNotReportData) {
            this._data.next(data);
            return;
        }

        try {
            let formatted = (data as ReportDto[]).map(item => {
                return {
                    count: Number(item.count.toFixed(2)),
                    name: item.name
                };
            });

            let report: ReportModel = {
                series: formatted.map((item: ReportDto) => item.count),
                labels: formatted.map((item: ReportDto) => item.name)
            };

            this._data.next(report);
        } catch (error) {}
    }

    protected filterToParams(filter: GeneralFilter): HttpParams {
        if (!filter) return new HttpParams();
        if (filter.source) filter.sourceType = undefined;
        let queryParams = new HttpParams();
        Object.keys(filter).map(k => {
            let val = (filter as any)[k];
            queryParams = val !== null && val !== undefined ? queryParams.append(k, val) : queryParams;
        });

        return queryParams;
    }

    protected isNeedToRefreshData(params: HttpParams): boolean {
        let str = params.toString();
        if (str === this._filterCache && this.dataCache) return false;
        return true;
    }

    protected isSameRequestPending(params: HttpParams): boolean {
        let str = params.toString();
        if (str === this._filterCache && this.isLoading) return true;
        return false;
    }

    protected cacheQueryParams(params: HttpParams): void {
        this._filterCache = params.toString();
    }

    protected callGet(endpoint: string, filter: GeneralFilter, isNotReportData?: boolean): Observable<any> {
        let queryParams = this.filterToParams(filter);
        if (!this.isNeedToRefreshData(queryParams)) {
            // this.changeData(this.dataCache, isNotReportData);
            return of(this.dataCache);
        }
        if (this.isSameRequestPending(queryParams)) return of(null);
        this.cacheQueryParams(queryParams);
        this.isLoading = true;
        this.isLoadingSubject.next(true);
        this.changeData(null);
        return this._http.get<any>(`${environment.apiUrl + endpoint}`, { params: queryParams }).pipe(
            map((res: any) => {
                this.isLoading = false;
                this.isLoadingSubject.next(false);
                return res;
            })
        );
    }
}
