<div class="sidebar" [class.open]="isBurgerOpen">
    <div class="sidebar__header">
        <div class="logo">
            <img class="icon" src="assets/icons/logo.svg" alt="" />
            <span class="label">Oko Intellect</span>
        </div>
        <div class="burger-close" (click)="closeBurger()">
            <img src="assets/icons/close.svg" alt="burger-close" />
        </div>
    </div>

    <ul class="sidebar__list">
        <app-sidebar-item *ngFor="let item of sidebarItems" [item]="item"></app-sidebar-item>
    </ul>
    <div class="btns">
        <a class="btn" [routerLink]="['/system', 'account']">
            <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 12C3.68466 12 0 13.5973 0 15.9893C0 18.3813 3.66182 19 8.00053 19C12.3159 19 16 18.4018 16 16.0107C16 13.6196 12.3387 12 8 12Z"
                    fill="#588B8B"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.98477 10.0059C10.5229 10.0059 12.58 7.94779 12.58 5.40969C12.58 2.8716 10.5229 0.814453 7.98477 0.814453C5.44667 0.814453 3.38858 2.8716 3.38858 5.40969C3.38001 7.93922 5.42382 9.99731 7.95239 10.0059H7.98477Z"
                    fill="#588B8B"
                />
            </svg>
        </a>
        <button class="btn" (click)="logout()">
            <img src="assets/icons/logout.svg" alt="" />
        </button>
    </div>
</div>
