import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_core/_services/auth.service';
import { TokenService } from 'src/app/_core/_services/token.service';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
    public isShowPassword: boolean;
    public form: FormGroup;
    public isLoading: boolean = false;

    constructor(private _authService: AuthService, private _tokenService: TokenService, private _router: Router) {
        this._createForm();
    }

    ngOnInit(): void {}

    public toggleIsShowPassword(): void {
        this.isShowPassword = !this.isShowPassword;
    }

    public handleSubmit(): void {
        this.isLoading = true;
        this._tokenService.clearTokens();
        this._authService
            .login({
                email: this.form.controls['email'].value,
                password: this.form.controls['password'].value
            })
            .subscribe({
                next: response => {
                    this._router.navigate(['system/monitoring']);
                    this._tokenService.clearTokens();
                    this._tokenService.saveAccessToken(response.token);
                    this._tokenService.saveRefreshToken(response.refreshToken);
                    this._router.navigate(['system', 'monitoring']);
                    this.isLoading = false;
                },
                error: () => (this.isLoading = false)
            });
    }

    private _createForm(): void {
        this.form = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.minLength(2)]),
            password: new FormControl('', [Validators.required, Validators.minLength(6)])
        });
    }
}
