import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './_core/_services/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'intellect-front';

    constructor(private _router: Router, private _authService: AuthService, private _toastrService: ToastrService) {}

    ngOnInit() {
        const { hostname } = new URL(window.location.href);
        const domains = hostname.split('.');
        // if (domains.length >= 3) {
        //     this._authService.locationExistanceChecker(domains[0]).subscribe({
        //         next: () => {},
        //         error: error => {
        //             if (error.status !== 500) {
        //                 this._router.navigate(['/404']);
        //             } else {
        //                 this._toastrService.error('Попробуйте позже', 'Ошибка сервера');
        //             }
        //         }
        //     });
        // }
    }
}
