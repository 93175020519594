<ng-container *ngIf="series">

  <ng-container *ngIf="series.length > 0">
    <app-pie-chart
        title="Категории"
        [series]="series"
        [labels]="labels"
        [colors]="colors"
        (dataClickEvent)="clickEventListener($event)"
    ></app-pie-chart>
  </ng-container>

  <ng-container *ngIf="series.length === 0">
    <app-pie-chart
        title="Категории"
        [series]="dummySeries"
        [labels]="dummyLables"
        [colors]="colors"
    ></app-pie-chart>
  </ng-container>

</ng-container>

<ng-container *ngIf="!series">
  <app-skeleton type="PIE_CHART"></app-skeleton>
</ng-container>
