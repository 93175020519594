import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MobileService {
    public isBurgerOpenObservable$: Observable<boolean>;
    private _isBurgerOpen: BehaviorSubject<boolean>;

    get isBurgerOpen(): boolean {
        return this._isBurgerOpen.value;
    }

    set isBurgerOpen(value: boolean) {
        this._isBurgerOpen.next(value);
    }

    public isFilterOpenObservable$: Observable<boolean>;
    private _isFilterOpen: BehaviorSubject<boolean>;

    get isFilterOpen(): boolean {
        return this._isFilterOpen.value;
    }

    set isFilterOpen(value: boolean) {
        this._isFilterOpen.next(value);
    }

    constructor() {
        this._isBurgerOpen = new BehaviorSubject<boolean>(false);
        this.isBurgerOpenObservable$ = this._isBurgerOpen.asObservable();

        this._isFilterOpen = new BehaviorSubject<boolean>(false);
        this.isFilterOpenObservable$ = this._isFilterOpen.asObservable();
    }
}
