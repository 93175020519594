import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ISidebarItem } from '../../_models/sidebar-item.model';
import { MobileService } from '../../_services/mobile.service';

@Component({
    selector: 'app-sidebar-item',
    templateUrl: './sidebar-item.component.html',
    styleUrls: ['./sidebar-item.component.scss']
})
export class SidebarItemComponent implements OnInit {
    @Input() item: ISidebarItem;

    constructor(private _router: Router, private _mobileService: MobileService) {}

    ngOnInit(): void {}

    public navigate(): void {
        this._router.navigate(['/system', this.item.link]);
        this._mobileService.isBurgerOpen = false;
    }
}
