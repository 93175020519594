<div class="auth">
    <div class="bg">
        <div class="right">
            <div class="content">
                <div class="title">Войдите в свою учетную запись</div>
                <form class="form" [formGroup]="form" (ngSubmit)="handleSubmit()">
                    <input class="form-control" formControlName="email" type="text" placeholder="Введите логин" />
                    <div class="password">
                        <input
                            class="form-control"
                            [type]="!isShowPassword ? 'password' : 'text'"
                            placeholder="Введите пароль"
                            formControlName="password"
                        />
                        <span class="hint" (click)="toggleIsShowPassword()">{{ !isShowPassword ? 'Показать' : 'Скрыть' }}</span>
                    </div>
                    <button class="btn" [disabled]="form.invalid || isLoading">
                        <ng-container *ngIf="!isLoading"> Войти </ng-container>
                        <img class="loader" *ngIf="isLoading" src="./assets/icons/loader.svg" alt="Loader" />
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
