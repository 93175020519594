<div class="projects">
    <div class="layout">
        <app-loader *ngIf="isLoading"></app-loader>
        <ng-container *ngIf="!isLoading && entries.length > 0">
            <div class="cards">
                <ng-container *ngFor="let item of entries">
                    <app-card-main [entry]="item" [tags]="searchTextTags"></app-card-main>
                </ng-container>
            </div>
            <div class="pagination" *ngIf="totalCount">
                <app-paginator [size]="20" [totalCount]="totalCount" [page]="page" (pageListener)="onPageClick($event)"></app-paginator>
            </div>
        </ng-container>
        <span class="no-items" *ngIf="!isLoading && entries.length < 1">Список пуст</span>
    </div>
    <app-right-sidebar class="right-sidebar">
        <h3>Фильтр</h3>
        <app-sidebar-filter
            [filters]="['startDate', 'endDate', 'sourceType', 'tone', 'entryType', 'bookmarkId', 'projectId', 'source']"
            (filterChangeEvent)="filterListener($event)"
        ></app-sidebar-filter>
    </app-right-sidebar>
</div>
