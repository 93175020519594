import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { GeneralFilter } from 'src/app/_core/_models/filter-models/general-filter';
import { ReportDto } from 'src/app/_core/_models/report.model';
import { MonitoringService } from 'src/app/_core/_services/monitoring.service';
import { environment } from 'src/environments/environment';
import { MonitoringEntryModel } from '../_models/monitoring-entry.model';
import { ProjectDaysModel } from '../_models/project-days.model';

@Injectable({
    providedIn: 'root'
})
export class ProjectDaysService extends MonitoringService {
    constructor() {
        super();
    }

    public get(filter: GeneralFilter): Observable<ReportDto[]> {
        return super.callGet('/analytics/grouped-days', filter).pipe(
            map(res => {
                super.changeData(res);
                return res;
            })
        );
    }
}
