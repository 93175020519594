<div class="download-report-dialog">
    <h2 class="title">Выберите формат файла для отчета</h2>

    <form class="form" [formGroup]="form">
        <div class="form-control">
            <mat-form-field appearance="none" class="mat-form-field-wrapper">
                <mat-label class="label">Выберите формат отчета</mat-label>
                <mat-select (selectionChange)="clickListener($event)" panelClass="dropdown__select" [value]="chosenTypeFile">
                    <mat-option panel [value]="undefined">
                        <span class="option_clear"> Сбросить </span>
                    </mat-option>
                    <mat-option class="option" *ngFor="let item of typeFiles" [value]="item">
                        {{ item.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="btns">
            <button class="btn btn-primary" [disabled]="form.invalid || isLoading" (click)="sendReport()">
                <img class="loader" *ngIf="isLoading" src="./assets/icons/loader.svg" alt="Loader" />
                <ng-container *ngIf="!isLoading"> Скачать отчет </ng-container>
            </button>
            <button class="btn btn-primary" (click)="closeDialog()">Закрыть</button>
        </div>
    </form>
</div>
