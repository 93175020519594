import { AuthService } from './../_services/auth.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, throwError, switchMap, filter, take } from 'rxjs';
import { TokenService } from '../_services/token.service';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable({
    providedIn: 'root'
})
export class Interceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private _authService: AuthService, private _tokenService: TokenService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
        let authReq = req;

        const accessToken = this._tokenService.getAccessToken();

        if (accessToken) {
            authReq = this.addTokenHeader(req, accessToken);
        }

        return next.handle(authReq).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse && !authReq.url.includes('auth/login') && error.status === 401) {
                    return this.handle401Error(authReq, next);
                }
                return throwError(error);
            })
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            const refreshToken = this._tokenService.getRefreshToken();

            if (refreshToken) {
                return this._authService.refreshToken(refreshToken).pipe(
                    switchMap((token: any) => {
                        this.isRefreshing = false;
                        this._tokenService.saveAccessToken(token.token);
                        this._tokenService.saveRefreshToken(token.refreshToken);
                        this.refreshTokenSubject.next(token.token);
                        return next.handle(this.addTokenHeader(request, token.token));
                    }),
                    catchError(err => {
                        this.isRefreshing = false;
                        this._authService.logout();
                        return throwError(err);
                    })
                );
            } else {
                this._authService.logout();
            }
        }

        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap(token => next.handle(this.addTokenHeader(request, token)))
        );
    }

    private addTokenHeader(request: HttpRequest<any>, token: string) {
        return request.clone({
            headers: request.headers.set(TOKEN_HEADER_KEY, `Bearer ${token}`)
        });
    }
}
