<header class="header" id="header">
    <div class="mobile-burger" (click)="openBurger()">
        <img src="assets/icons/menu.svg" alt="menu" />
    </div>
    <ng-container *ngIf="isShow">
        <form class="form" [formGroup]="form" (submit)="navigateToSearch()">
            <div class="search">
                <img class="icon" src="assets/icons/search.svg" alt />
                <input class="search-input" type="text" placeholder="Поиск" formControlName="search" />
            </div>
            <button class="btn btn-primary" (click)="navigateToSearch()">Поиск</button>
        </form>
    </ng-container>
    <div class="mobile-filter" (click)="openFilter()" [class.hide]="isNotification || !isShow || isAccount">
        <img src="assets/icons/filter.svg" alt="filter" />
    </div>
</header>
