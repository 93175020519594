import { Component, OnInit, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TagModel } from '../../_models/tag-model';
import { TagService } from '../../_services/tag.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-tag-change',
    templateUrl: './tag-change.component.html',
    styleUrls: ['./tag-change.component.scss']
})
export class TagChangeComponent implements OnInit {
    public tags: Array<TagModel> = [];
    public formArray: FormArray;
    public newFormGroup: FormGroup;
    public projectId: number;

    public isAddLoading: boolean = false;
    public isEditLoading: boolean = false;
    public isDeleteLoading: boolean = false;
    public isDataLoading: boolean = false;

    constructor(
        private _tagService: TagService,
        public dialogRef: MatDialogRef<TagChangeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _fb: FormBuilder,
        private toastr: ToastrService
    ) {
        this.projectId = data.projectId;
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    ngOnInit(): void {
        this.isDataLoading = true;
        this.newFormGroup = this.genFormGroup();
        this._tagService.getByProjectId(this.projectId).subscribe({
            next: res => {
                this.tags = res;
                this.formArray = this.genFormArray(this.tags);
                this.isDataLoading = false;
            },
            error: () => (this.isDataLoading = false)
        });
    }

    public genFormArray(tags: Array<TagModel>): FormArray {
        let fa: FormArray = this._fb.array([]);
        tags.forEach(proj => {
            let fg: FormGroup = this.genFormGroup();
            fg.reset(proj);
            fa.push(fg);
        });
        fa.disable();
        return fa;
    }

    public change(fg: FormGroup): void {
        this.isEditLoading = true;
        let tag = this.fgToModel(fg);
        fg.disable();
        this._tagService.update(tag).subscribe({
            next: () => {
                this.isDeleteLoading = false;
                this.toastr.success('Тэг успешно изменён ');
            },
            error: () => {
                this.isDeleteLoading = false;
                this.toastr.error('Ошибка, тэг не изменён');
            }
        });
    }

    public delete(fg: FormGroup, i: number): void {
        if (!confirm('Вы уверены, что хотите удалить тэг? ')) {
            return;
        }
        this.isDeleteLoading = true;
        let project = this.fgToModel(fg);
        this._tagService.delete(project.id).subscribe({
            next: response => {
                this.formArray.removeAt(i);
                this.isDeleteLoading = false;
                this.toastr.success('Тэг успешно удалён ');
            },
            error: () => {
                this.isDeleteLoading = false;
                this.toastr.error('Ошибка, тэг не удалён');
            }
        });
    }

    public add(): void {
        this.isAddLoading = true;
        let project = this.fgToModel(this.newFormGroup);
        this._tagService.create({ name: project.name, projectId: this.projectId }).subscribe({
            next: response => {
                let fg = this.genFormGroup(response);
                fg.disable();
                this.formArray.insert(0, fg);
                this.newFormGroup = this.genFormGroup();
                this.isAddLoading = false;
                this.toastr.success('Тэг успешно добавлен ');
            },
            error: () => {
                this.isAddLoading = false;
                this.toastr.error('Ошибка, тэг не добавлен');
            }
        });
    }

    public genFormGroup(tag?: TagModel): FormGroup {
        return this._fb.group({
            id: tag?.id ? tag.id : null,
            name: tag?.name ? tag.name : '',
            projectId: tag?.projectId 
        });
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    public fgToModel(fg: FormGroup): TagModel {
        let obj;
        if (fg) {
            obj = fg.getRawValue();
        } else {
            obj = this.newFormGroup.getRawValue();
        }

        return {
            id: obj.id,
            name: obj.name,
            projectId: obj.projectId
        };
    }
}
