<div class="bookmarks">
    <div class="layout">
        <div class="header">
            <div class="menu-list">
                <ng-container *ngIf="filter ">
                    <app-menu-item
                        label="Изменить избранное"
                        icon="assets/icons/edit.svg"
                        (click)="openBookmarksDialog()"
                    ></app-menu-item>
                </ng-container>

                <ng-container *ngIf="filter && filter.projectId">
                    <app-menu-item
                        label="Изменить тэги"
                        icon="assets/icons/edit.svg"
                        (click)="openTagDialog()"
                        title="Необходимо выбрать проект"

                    ></app-menu-item>
                </ng-container>

                <ng-container *ngIf="!filter || !filter.projectId">
                    <app-menu-item
                        class="menu-item_disabled"
                        label="Изменить тэги"
                        icon="assets/icons/edit.svg"
                    ></app-menu-item>
                </ng-container>
            </div>
        </div>
        <app-entry-list [filter]="filter"></app-entry-list>
    </div>
    <app-right-sidebar class="right-sidebar">
        <h3>Фильтр</h3>
        <app-sidebar-filter
            [filters]="['bookmarkId', 'projectId', 'startDate', 'endDate', 'sourceType','tone', 'entryType', 'source']"
            (filterChangeEvent)="onFilterChanges($event)"
        >
      </app-sidebar-filter>
    </app-right-sidebar>
</div>
