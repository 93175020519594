<ng-container *ngIf="control">
    <button
        class="btn"
        (click)="picker.open()"
    >
        <div class="logo">
            <img
                class="icon"
                src="/assets/icons/datepicker_white.svg"
                alt
            >
        </div>
        <div class="wrapper">
            <div
                class="label"
                [class.label_dark]="theme == 'dark'"
            >
                {{ label }}
            </div>
            <div class="date">
                {{
          control?.getRawValue()
            ? (control?.getRawValue() | date: "dd.MM.yy hh:mm")
            : "Дата не выбрана"
        }}
            </div>
        </div>
        <mat-form-field
            class="form-field"
        >
            <input
                matInput
                [ngxMatDatetimePicker]="picker"
                placeholder="Choose a date"
                [formControl]="control"
                [min]="minDate"
                [max]="maxDate"
            >
            <ngx-mat-datetime-picker
                #picker
                [showSpinners]="showSpinners"
                [showSeconds]="showSeconds"
                [stepHour]="stepHour"
                [stepMinute]="stepMinute"
                [stepSecond]="stepSecond"
                [touchUi]="touchUi"
                [color]="color"
                [enableMeridian]="enableMeridian"
                [disableMinute]="disableMinute"
                [hideTime]="hideTime"
            >
            </ngx-mat-datetime-picker>
        </mat-form-field>
    </button>
</ng-container>
