import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProjectFilter } from '../_models/filter-models/project-filter';
import { ProjectModel } from '../_models/project-model';
import { ProjectReportModel } from '../_models/project-report.model';

@Injectable({
    providedIn: 'root'
})
export class ProjectService {
    private readonly _data;
    public _data$;
    private readonly _filteredData;
    public _filteredData$;

    constructor(private _http: HttpClient) {
        this._data = new Subject<ProjectModel[]>();
        this._data$ = this._data.asObservable();

        this._filteredData = new Subject<ProjectModel[]>();
        this._filteredData$ = this._data.asObservable();
    }

    private changeData(data: Array<ProjectModel>): void {
        this._data.next(data);
    }

    public getProjects(): Observable<any> {
        return this._http.get(`${environment.apiUrl}`);
    }

    public getAll(): Observable<Array<ProjectModel>> {
        return this._http.get<Array<ProjectModel>>(`${environment.apiUrl}/projects`).pipe(
            map(res => {
                this.changeData(res);
                return res;
            })
        );
    }

    public getById(projectId: number): Observable<ProjectModel> {
        let queryParams = new HttpParams();
        queryParams.append('projectId', projectId);
        return this._http.get<ProjectModel>(`${environment.apiUrl}/projects`, {
            params: queryParams
        });
    }

    public create(project: Partial<ProjectModel>): Observable<ProjectModel> {
        return this._http.post<ProjectModel>(`${environment.apiUrl}/projects`, {
            ...project
        });
    }

    public update(project: ProjectModel): Observable<ProjectModel> {
        return this._http.put<ProjectModel>(`${environment.apiUrl}/projects/${project.id}`, {
            ...project
        });
    }

    public delete(projectId: number): Observable<void> {
        let queryParams = new HttpParams();
        queryParams.append('projectId', projectId);
        return this._http.delete<void>(`${environment.apiUrl}/projects/${projectId}`);
    }

    public getByFilter(projectFilter: ProjectFilter): Observable<ProjectReportModel> {
        let queryParams = new HttpParams();
        Object.keys(projectFilter).map(k => {
            queryParams = queryParams.append(k, (projectFilter as any)[k]);
        });

        return this._http.get<ProjectReportModel>(`${environment.apiUrl}/projects/filter`, {
            params: queryParams
        });
    }
}
