import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MobileService } from '../../_services/mobile.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public form: FormGroup;
    public control: FormControl;
    public isShow: boolean = true;
    public isNotification: boolean = false;
    public isAccount: boolean = false;
    constructor(private _router: Router, private _route: ActivatedRoute, private _mobileService: MobileService) {
        this.form = new FormGroup({
            search: new FormControl('', [Validators.required])
        });
    }

    ngOnInit(): void {
        this._route.queryParams.subscribe(params => {
            this.form.controls['search'].setValue(params['q']);
        });
    }

    public navigateToSearch(): void {
        this._router.navigate(['/system/search'], {
            queryParams: {
                q: this.form.controls['search'].value.trim()
            }
        });
    }

    public openBurger(): void {
        this._mobileService.isBurgerOpen = true;
    }
    public openFilter(): void {
        this._mobileService.isFilterOpen = true;
        console.log(this._mobileService.isFilterOpen);
    }
}
