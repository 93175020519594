import { Component, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ChartComponent } from '../chart/chart.component';

@Component({
    selector: 'app-source-pie-chart',
    templateUrl: './source-pie-chart.component.html',
    styleUrls: ['./source-pie-chart.component.scss']
})
export class SourcePieChartComponent {
    @Input() title: string;
    @Input() colors: string[];
    @Input() series: number[];
    @Input() labels: string[];

    public dummySeries = [1];
    public dummyLables = ['Нет данных'];

    constructor(private _router: Router, private zone: NgZone) {}

    public clickEventListener(labelInd: number): void {
        this.zone.run(() => {
            this._router.navigate(['/system/sources', this.labels[labelInd]]);
        });
    }
}
