import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { ChartComponent as ApexChartComponent } from 'ng-apexcharts';

@Component({ template: '' })
export class ChartComponent implements OnInit, AfterViewInit {
    @ViewChild('chart') chartView: ApexChartComponent;

    private _title: string;
    @Input()
    public set title(t: string) {
        this._title = t;
    }
    public get title(): string {
        return this._title;
    }

    private _colors: string[];
    @Input()
    public set colors(c: string[]) {
        this._colors = c;
    }
    public get colors(): string[] {
        return this._colors;
    }

    _series: any[] = [];
    @Input()
    public set series(s: any[]) {
        setTimeout(() => {
            this.chartView.updateSeries(s);
        }, 0);
    }
    public get series(): any[] {
        return this._series;
    }

    private _labels: string[] = [];
    @Input()
    public set labels(l: string[]) {
        this._labels = l || [''];
    }
    public get labels(): string[] {
        return this._labels;
    }

    @Output()
    public dataClickEvent = new EventEmitter<number>();

    public chart: any = {
        width: '100%',
        height: 300,
        type: 'pie',
        events: {
            dataPointSelection: (event: any, chartContext: any, config: any) => {
                this.dataClickEvent.emit(config.dataPointIndex);
            }
        }
    };

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        // let i = 0;
        // let s: number[] = [];
        // let l: string[] = [];
        // // this.colors = ['#e2e2e2', '#e9e9e9'];
        // let a = 1;
        // let interval = setInterval((qq: any) => {
        //     i += a;
        //     if (this.series.length % 5 === 0) a = a - a * 2;
        //     console.log('qq');
        //     this.series = s = [...s, i];
        //     // this.labels = l = [...l, i.toString()];
        // }, 2000);
        // this.chart.setLocale('ru');
    }
}
