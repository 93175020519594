import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
    @Input() label: string;
    @Input() list: Array<any>;
    @Input() value?: any;
    @Input() isSecondary?: boolean;

    @Output() clickEvent = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    public clickListener(item: any) {
        this.clickEvent.emit(item.value);
    }
}
