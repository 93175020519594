import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, of, Observable } from 'rxjs';
import { GeneralFilter } from 'src/app/_core/_models/filter-models/general-filter';
import { ReportDto } from 'src/app/_core/_models/report.model';
import { MonitoringService } from 'src/app/_core/_services/monitoring.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SourceReportService extends MonitoringService {
    constructor() {
        super();
    }

    protected override changeData(data: ReportDto[]): void {
        this._data.next(data);
    }

    public get(filter: GeneralFilter): Observable<any> {
        return super.callGet('/analytics/grouped-social-source', filter).pipe(
            map(res => {
                this.changeData(res);
                return res;
            })
        );
    }
}
