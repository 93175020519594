<div class="account">
    <h2 class="title">Данные о пользователе</h2>

    <div class="form">
        <div class="subheader">Пользователь/Логин</div>
        <div class="form__item" *ngIf="email">
            <div class="label">Email</div>
            <div class="value">{{ email }}</div>
        </div>

        <div class="form__item" *ngIf="location">
            <div class="label">Местоположение</div>
            <div class="value">{{ location }}</div>
        </div>
    </div>
</div>
