import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GeneralFilter } from '../_models/filter-models/general-filter';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    constructor(private _http: HttpClient) {}

    public search(filter: GeneralFilter): Observable<any> {
        let queryParams = new HttpParams();

        Object.keys(filter).map(k => {
            if ((filter as any)[k] !== null) {
                queryParams = queryParams.append(k, (filter as any)[k]);
            }
        });

        return this._http.get(`${environment.apiUrl}/search`, { params: queryParams });
    }
}
