import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-skeleton',
    templateUrl: './skeleton.component.html',
    styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit {
    @Input() type: SKELETON;
    constructor() {}

    ngOnInit(): void {}
}

export enum SKELETON {
    BAR_CHART,
    PIE_CHART,
    TABLE
}
